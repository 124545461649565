import React,{ useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import HomeBanner from '../components/HomeBanner';
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
import NewsAndBlogs from '../components/NewsAndBlogs';
import PageLoader from '../components/PageLoader';
import UpcommingSeminars from '../components/UpcomingSeminars';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBannerDataAsync,fetchLatestSeminarBlogAsync,bookSeatAsync } from '../redux/auth/userSlice';
import GuestUserModal from '../components/GuestUserModal';
import AboutUs from './AboutUs';
const Home = () => {
    const [latestBlogSeminar, setLatestBlogSeminar] = useState('');
    const {bannerloading, userLoading} = useSelector((state)=>state.user);
    const {responseData} = useSelector((state)=>state.auth);
    const [modalShow, setModalShow] = useState('');
    const [seminarId,setSeminarId] = useState('');
    const [bannerData,setBannerData] = useState('');
    const [aboutUsData,setAboutUsData] = useState('');
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchBannerDataAsync('home')).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setBannerData(payload.data);
            }
        })
    },[])
    useEffect(()=>{
        dispatch(fetchLatestSeminarBlogAsync('home')).then((result)=>{
            if(result.payload.status === true){
                setLatestBlogSeminar(result.payload.data);
            }
        });
    },[])
    useEffect(()=>{
        dispatch(fetchBannerDataAsync('about-us')).then((result)=>{
            if(result.payload.status === true){
                setAboutUsData(result.payload.data);
            }
        });
    },[])
    const { latestPosts, upcomingSeminars } = latestBlogSeminar;

    const bookSeats = (seminarId) => {
        setSeminarId(seminarId);
        let ObjectData ={}
        if(responseData){
            ObjectData.name = responseData.data.name;
            ObjectData.email = responseData.data.email;
            ObjectData.seminar = seminarId;
            dispatch(bookSeatAsync(ObjectData)).then((result)=>{
                const payload = result.payload;
                if(payload.status){
                    let checkoutUrl = payload.session.url;
                    window.location.href=checkoutUrl;
                }
            })
        }else{
            setModalShow(true);
        }
    }
    return (
        <>
            {/* <PageLoader/> */}
            <NavbarTop />
            <HomeBanner bannerData={bannerData}/>
            <section className='home-news-sec position-relative ptb-100'>
                {userLoading ?
                <PageLoader/>:
                    <Container>
                        <Row className="justify-content-between">
                            <Col sm={12} md={6}>
                                <div className='up-seminars-outer-home'>
                                    <div className='titel-holder'>
                                        <h2>Upcoming Seminars</h2>
                                    </div>
                                    {(upcomingSeminars !== undefined && upcomingSeminars.length >0 ) ? 
                                    upcomingSeminars.map((data, index) => (
                                        <UpcommingSeminars data={data} key={index} bookSeats={bookSeats} seminarId={seminarId}/>
                                    )):<p className="text-center">No Data Found</p>}
                                </div>   
                            </Col>
                            <Col sm={12} md={6}>
                                <div className='news-blog-outer-home'>
                                    <div className='titel-holder'>
                                        <h2>News & Blogs</h2>
                                    </div>
                                    {(latestPosts !== undefined && latestPosts.length >0 ) ? 
                                        latestPosts.map((data, index) => (
                                        <NewsAndBlogs data={data} key={index} />
                                    )):<p className="text-center">No Data Found</p>}
                                </div>                        
                            </Col>
                        </Row>
                        <GuestUserModal show={modalShow} setModalShow={setModalShow} seminarId={seminarId}/>
                    </Container>
                }
            </section>
            <AboutUs aboutUsData={aboutUsData}/>
            <CopyFooter />
        </>
    );
}
export default Home;