import React from 'react';
import './editprofile.css';
import { Card, Image } from 'react-bootstrap';

const EditProfile = ({onChangeProfile, image,validationErrorMessage})=>{
    return (
        <>
            <Card className='editprofilecard'>
                <div className='editprofileimg'>
                    <Image className='h-100 w-100 object-fit-cover object-position-center' alt="profile image" src={image}/>
                </div>
                <div className='uploadprofilebox'>
                    <input id='uploadprofile' type="file" onChange={onChangeProfile} className="filetype" />
                    <label className='custom-btn fill-btn shodow-btn'  htmlFor="uploadprofile">Edit Profile Picture</label>
                </div>
                <span className='error text-danger'>{validationErrorMessage.profile_image}</span>
            </Card>
        </>
    )
}
export default EditProfile;