import React from 'react';
import './index.css';
import { Col, Badge, Image } from "react-bootstrap";

 
const VipBoard = ({data})=>{ 
    return (
        <>    
            <Col lg={4} md={6} sm={12}>
                <div className='vipouter'>
                    <div className='viprow'>
                        <div  className='vipimg'><Image className='h-100 w-100 object-fit-cover' src={data.profile_image_url ? data.profile_image_url : '/asset/images/vip/01.png'} alt='vip' /></div>
                        <div className='vip-name'>{data.name}</div>
                        <div className='vipbages'>
                            <Badge><Image src='/asset/images/vip.svg' alt='vip' /> vIP</Badge>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}
export default VipBoard;