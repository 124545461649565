import React,{useEffect, useState} from 'react';
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
import SideBar from '../components/Sidebar';
import { Container, Row, Col, Card, Image } from "react-bootstrap";
// import { Link} from 'react-router-dom';
import EditProfile from '../components/EditProfile';
import WantBreak from '../components/WantBreak';
import EditProfileForm from '../form/EditProfileForm';
import ChangePassword from '../form/ChangePassword';
import {useDispatch, useSelector } from 'react-redux';
import {updateUserProfileAsync, updateUserProfilePicAsync, changePasswordAsync} from '../redux/auth/userSlice';
import {fetchUserDetailsAsync } from '../redux/auth/authSlice';

import { validationErrors } from '../request/errorHandler';
import PageLoader from '../components/PageLoader';

const ProfileSetting = () => {
    const [toggleClass, setToggleClass] = useState(false);
    const [image, setImage] = useState("/asset/images/user.png")
    const [isBreak, setIsBreak] = useState(false);
    const { auth: { responseData, loading }, user: { action, userError, userLoading }} = useSelector((state)=>state);
    const validationErrorMessage = validationErrors(userError);
    const[isDisabled, setIsDisabled] = useState(true);
    const dispatch = useDispatch();

    /** update profile details  */
    const onSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target); 
        dispatch(updateUserProfileAsync(formData)).then((result)=>{
        let response = result.payload;
        if(response.status === true){
            setIsDisabled(true);
            dispatch(fetchUserDetailsAsync());
        }
        });
    };

    /** update profile pic  */
    const onChangeProfile = (e) => {
        const file = e.target.files[0];
        if(file !== undefined) {
            const fileSize = file.size;
            const fileType = file.type;
            const maxFileSize = 5 * 1024 * 1024; // 5MB
            setImage(URL.createObjectURL(e.target.files[0]));
            if (fileType != "text/csv") {
            } else if (fileSize > maxFileSize) {
            }
            const formData = new FormData();
            formData.append("profile_image", file);
            dispatch(updateUserProfilePicAsync(formData)).then((result)=>{
                let response = result.payload;
                if(response.status === true){
                    dispatch(fetchUserDetailsAsync());
                }
            });
        }
    }

    /** change password  */
    const handleChangePassword = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target); 
        dispatch(changePasswordAsync(formData));
    }

    useEffect(()=>{
        if(responseData !=='' && responseData !== null){
            let image = responseData.data.profile_image ? responseData.data.profile_image : '/asset/images/user.png';
            let breaks = responseData.data.is_active;
            setImage(image);
            setIsBreak(breaks);
        }
    },[responseData]);
    return (
        <>
            {loading ? <PageLoader/>:''}
            <NavbarTop />
            <div className={toggleClass ? "maincontentouter opensidebar":"maincontentouter"}>
                <div className='leftsidebar'>
                    <SideBar setToggleClass={setToggleClass} toggleClass={toggleClass}/>
                </div>
                <div className='maincontent'>
                    <div className='dashboardtext'>
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <Card className='profilesettingcard'>
                                        <div className='profilesettingrow'>
                                            <div className='profilesettingleft'>
                                                <EditProfile onChangeProfile={onChangeProfile} image={image} validationErrorMessage={validationErrorMessage}/>
                                            </div>
                                            <div className='profilesettingright position-relative'>
                                                <div className='d-flex justify-content-between gap-4'>
                                                    <h2 className='after-line'>Profile Details</h2>
                                                    <span className='editprofile' to="#" ><Image src='/asset/images/edit.svg' alt='edit' onClick={()=>{setIsDisabled(!isDisabled)}}/> </span>
                                                </div>
                                                <EditProfileForm action={action}  userLoading={userLoading} responseData={responseData} isDisabled={isDisabled} onSubmit={onSubmit} validationErrorMessage={validationErrorMessage}/>
                                            </div>
                                        </div>
                                        <div className='profilesettingrow'>
                                            <div className='profilesettingleft'>
                                                <WantBreak isBreak={isBreak}/>
                                            </div>
                                            <div className='profilesettingright cpbox'>
                                                <h2 className='after-line'>Change password</h2>
                                                <ChangePassword action={action} userLoading={userLoading} handleChangePassword={handleChangePassword} validationErrorMessage={validationErrorMessage}/>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <CopyFooter />
                </div>
            </div>            
        </>
    );
}
export default ProfileSetting;