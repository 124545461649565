import React from 'react';
import './index.css';
import { Row, Col ,ProgressBar,Image, Card, CardText } from "react-bootstrap";

 
const QuoteDay = ({quotesMessage,quotesPercentage})=>{ 
    const now = 100-quotesPercentage;
    return (
        <>
            <Card className='quotedaycard'>
                <Row>
                    <Col lg={8} md={6} sm={12} className='align-self-center'> 
                        <div className='quoteday-progress'>
                            <h2 className="after-line">Quote of the day</h2>            
                            <ProgressBar  now={now} data-content={quotesMessage !==null ? quotesMessage:''}/>
                            <CardText>{quotesPercentage}% of people have completed their task few more to go!</CardText>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12}>                    
                        <div className='quotedayimg text-end'>
                            <Image src='/asset/images/quotedayimg.png' alt='quotedayimg' />
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
export default QuoteDay;