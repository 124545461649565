import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

const AboutUs = ({aboutUsData}) => {
    console.log(aboutUsData,'aboutUsData');
  return (
    <>
        <section className='home-about ptb-100'>
            <Container>
                <Row className="justify-content-between">
                    <Col sm={12} md={12}>
                        <div className='home-about-text'>
                            <span dangerouslySetInnerHTML={{ __html: aboutUsData ? aboutUsData.sections[0].content_text: '' }} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='tueyou–pursue-sec ptb-100'>
            <Container>
                <Row className='align-items-center'>
                    <Col sm={12} md={6}>
                        <div className='tueyou–pursue-img'>
                            <div className='img-inner'>
                                <Image src={aboutUsData ? aboutUsData.sections[1].image_url: ''} alt='IMG-1' />
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className=''>
                            <span dangerouslySetInnerHTML={{ __html: aboutUsData ? aboutUsData.sections[1].content_text: '' }} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='about-sec ptb-100'>
            <Container>
                <Row className='align-items-center'>
                    <Col sm={12} md={6}>
                        <div className=''>
                            <span dangerouslySetInnerHTML={{ __html: aboutUsData ? aboutUsData.sections[2].content_text: '' }} />
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className='about-sec-img'>
                            <div className='img-inner'>
                                <Image src={aboutUsData ? aboutUsData.sections[2].image_url: ''}  alt='IMG-1' />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}

export default AboutUs
