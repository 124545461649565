import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import GuestUserForm from '../../form/GuestUserForm';
import { useDispatch, useSelector } from 'react-redux';
import { bookSeatAsync,ResetUserStateAsync} from '../../redux/auth/userSlice';

const GuestUserModal = (props) => {
    const {show, setModalShow, seminarId} =props;
    const dispatch = useDispatch();
    const {userLoading, userError} = useSelector((state)=>state.user);
    const bookSeats = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target); 
        formData.set('seminar', seminarId);
        dispatch(bookSeatAsync(formData)).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                let checkoutUrl = payload.session.url;
                window.location.href=checkoutUrl;
            }
        })
    }
    useEffect(()=>{
        if(show === false)
        dispatch(ResetUserStateAsync());
    },[show])
   
    return (
        <>
           <Modal show={show} centered className='taskmodal guestusermodalmain'>
                <Modal.Header closeButton onClick={()=>{setModalShow(false)}}>
                </Modal.Header>
                <Modal.Body>
                    <div className='taskmodaltext'>
                        <h1>Please enter the detail for complete your payment</h1>
                        {/* <p>Lorem Empowering individuals to fulfill their full potential and live their dreams.</p> */}
                        <GuestUserForm bookSeats={bookSeats} userLoading={userLoading} userError={userError}/>
                    </div>
                </Modal.Body>                
            </Modal>
        </>
    );
}
export default GuestUserModal;
