import React from 'react';
import './index.css';
import {Card } from "react-bootstrap";

 
const MyRewards = ({rating})=>{ 

    return (
        <>
        
            <h2 className="after-line">Rewards</h2>   
            <Card className='rewardscrad'>
                <div className='rewardscradtext'>
                    <h3>Your rewards as stars</h3>   
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
        
                <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                    let ratingClass ="off";
                    if(index < rating){
                        ratingClass ="on";
                    }
                    return (
                    <button
                        type="button"
                        key={index}
                        className={ratingClass}
                    >
                        <span className="star">&#9733;</span>
                    </button>
                    );
                })}
                </div>
            </Card>
        </>
    )
}
export default MyRewards;