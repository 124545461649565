// store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userSlice from './auth/userSlice';
const store = configureStore({
  reducer: {
    auth: authReducer,
    user:userSlice,
    // other reducers
  },
});

export default store;
