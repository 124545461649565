import React from 'react';
import './upcommingseminars.css';
import { Image } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
const UpcommingSeminars = ({data,bookSeats}) => {
    return(
        <>
            <div className='seminarcardouter'>
                <Card className='seminarcard'>
                    <div className='cradimg overflow-hidden'>  
                        <Image className='h-100 w-100 object-fit-cover' src={(data !==undefined) ? data.imageUrl : '/asset/images/no-image.jpg'}  alt='card img'/>
                    </div>
                    <div className='cradtextouter d-flex flex-wrap'>
                        <div className='cradtext'>
                            <Card.Title>{(data !==undefined) ? data.title : 'True You Club Realised Your Potential'}</Card.Title>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li" className='meteor'>                                  
                                    <Image src='/asset/images/location.svg' alt='list icon'/> {(data !==undefined) ? data.venue : 'Gotham Hall, 1356 Broadway, USA'} 
                                </ListGroup.Item>
                                <ListGroup.Item as="li">                                  
                                    <Image src='/asset/images/calendar-dark.svg' alt='list icon'/> {(data !==undefined) ? data.datetime : ' 10 February 2024 09:00AM EST'} 
                                </ListGroup.Item>
                            </ListGroup>
                            {/* <CountDownTimer dateTime={startTime}/> */}
                        </div>
                        <div className='cradlink'>
                        {data.isBookingClosed ? 
                            <div className='leftsets text-danger'><span>Booking Closed</span></div>:
                            <>
                            <Button type="button" className='custom-btn light-color-btn' onClick={()=>bookSeats(data.id)}>Book Seats</Button>
                            {data.remain_ticket < 10 ?  <div className='leftsets'>Only {data.remain_ticket} Seats Left!</div> : ''}
                            </>
                        }   
                        </div>
                    </div>
                </Card>
            </div>
        </>
    )
}
export default UpcommingSeminars;