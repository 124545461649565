import React from 'react';
import './index.css';
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoader from '../PageLoader';

const HomeBanner = ({bannerData})=>{
    const {bannerloading} = useSelector((state)=>state.user);
    let buttonComponent = '';
    if(bannerData !== undefined && bannerData.button!== null){
        if(bannerData.button !== undefined){
            let buttons = JSON.parse(bannerData.button);
            buttonComponent=  buttons.map((data, index) => (
                <Link className='custom-btn fill-btn shodow-btn' to={data.link} key={index}>{data.title}</Link>
                )
            )
        }
    }
    return (
        <>
            {bannerloading ?<PageLoader/> :''}
            <section className='hero-banner' style={{backgroundImage: `url('/asset/images/banner-bg.png')`, backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat" }}>
                <Container>
                    <Row className="justify-content-between">
                        <Col sm={12} md={6} lg={6} className='align-self-center'>
                            <div className='hero-text'>
                                <h1>{(bannerData !== undefined)? bannerData.title:'dfdfdf'}</h1>
                                <p className='f-20'>{(bannerData !== undefined)? bannerData.subtitle:'dfdfdf'}</p>
                                {(buttonComponent)&&<div className='herobuttons'>{buttonComponent} </div>}
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={5} className='align-self-end'>
                            <div className='banner-img'>
                                <Image src={(bannerData !== undefined)? bannerData.image_url:'/asset/images/banner-bg.png'}/>
                            </div>                            
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default HomeBanner;