import React from 'react'
import './auth/login.css';
import {
  Form,
  FormLabel,
  FormControl,
  Col,
  Row,
  InputGroup,
  Button,
  Image
} from 'react-bootstrap';

const GuestUserForm = ({bookSeats, userError, userLoading}) => {
  return (
    <>
        <Form className='form-input' onSubmit={bookSeats}>
            <Row>
                <Col className='text-left'>
                    <Form.Group className="form-row text-start">
                        <div className='position-relative'>
                            <FormLabel>Name</FormLabel>
                            <InputGroup.Text>
                            <Image src='/asset/images/user.svg' alt='user' />
                            </InputGroup.Text>
                            <FormControl type="text"  placeholder="Enter Your Name" name="name" />
                        </div>
                        <span className='error'>{userError !== null ? userError.errors.name : ''}</span>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className='text-left'>
                    <Form.Group className="form-row text-start">
                        <div className='position-relative'>
                            <FormLabel>Email</FormLabel>
                            <InputGroup.Text>
                            <Image src='/asset/images/mail.svg' alt='mail' />
                            </InputGroup.Text>
                            <FormControl type="email"  placeholder="Enter Your Email" name="email"/>
                        </div>
                        <span className='error'>{userError !== null ? userError.errors.email : ''}</span>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button type="submit"  className='custom-btn fill-btn shodow-btn w-100'>{userLoading ? 'Loading..':'Next'}</Button>
                </Col>
            </Row>
        </Form>
    </>
  )
}

export default GuestUserForm
