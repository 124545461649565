import { toast } from "react-toastify";
import {codeMessage} from "./codeMessage";
export const errors = (response) => {
    const { data } = response;
    if(response.status === 401){
        if (data && data.status === false) {
            toast.error(data.error, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }else{
            // localStorage.removeItem("user_data");
        }
    }else if(response.status === 422){
        if(response !==null && response.hasOwnProperty("errors")){
            return response.errors;
         }
         return '';
    }else{
        toast.error(codeMessage[response.status], {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
}

export const validationErrors = (response) => {
    if(response === undefined) return '';
    if(response !==null && response.hasOwnProperty("errors")){
       return response.errors;
    }else if (response && response.status === false) {
        toast.error(response.error, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }else if(response !==null) {
        if(response.status !== 401 && response.status !== 422)
        toast.error(codeMessage[response.status], {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return '';
}