import React,{useState, useEffect} from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
import ListGroup from 'react-bootstrap/ListGroup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleNewsDetailsAsync } from '../redux/auth/userSlice';
import PageLoader from '../components/PageLoader';
import PageNotFound from '../pages/PageNotFound';
const BlogDetail = () => {
    const {userLoading} = useSelector((state)=>state.user);
    const [newsDetails, setNewsDetails] = useState('');
    const [isPageExpired, setIsPageExpired] = useState(false);
    const dispatch = useDispatch();
    const { slug } = useParams();
    useEffect(()=>{
        dispatch(fetchSingleNewsDetailsAsync(slug)).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setNewsDetails(payload.data);
            }else if(payload.response !== undefined){
                if(payload.response.status === 404){
                    setIsPageExpired(true);
                }
            }
        })
    },[])
    return (
        <>
            {userLoading ? <PageLoader/>:''}
            {isPageExpired ? <PageNotFound/>:
                <>
                <NavbarTop />
                    <section className='blogdetailbanner ptb-100' style={{backgroundImage: `url('/asset/images/banner-bg.png')`, backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat" }}>
                        <Container>
                            <Row className='justify-content-center'>
                                <Col md={8} xs={12}>
                                    <div className='blogdetailbannertext text-center'>
                                        <h1>{newsDetails.title}</h1>
                                        <ListGroup as="ul" className='blogauther-detail'>
                                            <ListGroup.Item as="li">                                  
                                                <span><Image src='/asset/images/user-1.svg' alt='user-1'/></span>  By : {newsDetails.created_by}
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">                                  
                                                <span><Image src='/asset/images/calendar-dark.svg' alt='calendar-dark'/></span> {newsDetails.publish_date}
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className='blogdetailcontent-sec'>
                        <Container>
                            <Row className='justify-content-center'>
                                <Col md={8} xs={12}>
                                    <div className='blogdetailcontent'>
                                        <Image className='large-img' src={newsDetails.image_url ? newsDetails.image_url : '/asset/images/no-image.jpg'} alt='blog-fetaure-img' />
                                        <span dangerouslySetInnerHTML={{ __html: newsDetails.content }} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                <CopyFooter />
                </>
            }
        </>
    );
}
export default BlogDetail;