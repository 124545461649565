import React,{useState,useEffect} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
import NewsAndBlogs from '../components/NewsAndBlogs';
import UpcommingSeminars from '../components/UpcomingSeminars';
import ConfirmPasswordForm from '../form/auth/ConfirmPasswordForm';
import { useNavigate,useParams  } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchResetPasswordAsync } from '../redux/auth/authSlice';
import { validationErrors } from '../request/errorHandler';
import { fetchLatestSeminarBlogAsync,fetchBannerDataAsync } from '../redux/auth/userSlice';
import PageLoader from '../components/PageLoader';
import GuestUserModal from '../components/GuestUserModal';

const ResetNewPassword = () => {
    const [isConfirmPasswordMatch, setIsConfirmPasswordMatch] = useState(false);
    const {loading, errors} = useSelector((state)=>state.auth);
    const {bannerloading} = useSelector((state)=>state.user);
    const validationErrorMessage = validationErrors(errors);
    const [setBannerData] = useState('');
    const [latestBlogSeminar, setLatestBlogSeminar] = useState('');
    const [modalShow, setModalShow] = useState('');
    const [seminarId,setSeminarId] = useState('');

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { token } = useParams();

    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target); 
        formData.set("token",token);
        dispatch(fetchResetPasswordAsync({ resetData: formData })).then((result)=>{
            let response = result.payload;
            if(response.status === true){
                navigate("/");
            }
        });
    }
    useEffect(()=>{
        dispatch(fetchLatestSeminarBlogAsync('login')).then((result)=>{
            if(result.payload.status === true){
                setLatestBlogSeminar(result.payload.data);
            }
        });
        dispatch(fetchBannerDataAsync('reset-password')).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setBannerData(payload.data);
            }
        })
    },[])
    const { news, seminar } = latestBlogSeminar;
    const bookSeats = (seminar) => {
        setSeminarId(seminar);
        setModalShow(true);
    }
    return (
        <>
            <NavbarTop />
            {/* <HomeBanner bannerData={bannerData}/> */}
            <section className='login-news-sec  ptb-100'>
                <Container>
                    <Row className="justify-content-between">
                        <Col sm={12} md={6}>
                            <ConfirmPasswordForm onSubmit={onSubmit} isConfirmPasswordMatch={isConfirmPasswordMatch} validationErrorMessage={validationErrorMessage} loading={loading}/>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className='news-blog-outer-home'>
                                <div className='titel-holder'>
                                    <h2>News & Blogs</h2>
                                </div>
                                { bannerloading ? <PageLoader/>:''}
                                { Object.keys(latestBlogSeminar).length >0 ? 
                                    <>
                                        {seminar && <UpcommingSeminars data={seminar} bookSeats={bookSeats}/>}
                                        {
                                        (news) ? 
                                            news.map((data, index) => (
                                                <NewsAndBlogs data={data} key={index} />
                                            )):''
                                        }
                                    </> : <p className="text-center">No Data Found</p>
                                }
                            </div>                        
                        </Col>
                    </Row>
                    <GuestUserModal show={modalShow} setModalShow={setModalShow} seminarId={seminarId}/>
                </Container>
            </section>
            <CopyFooter />
        </>
    );
}
export default ResetNewPassword;