import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CountDownTimer from '../CountDownTimer';
const TaskModal = ({completeTask,isVisibleTask,setIsVisibleTask}) => {
    const handleClose = () => setIsVisibleTask(false); 
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const formattedDateTime = tomorrow.toISOString().slice(0, 10) + " 00:00:00";
    return (    
        <>
           <Modal show={isVisibleTask} onHide={handleClose} centered className='taskmodal'>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='taskmodaltext'>
                        <h2>DID YOU COMPLETE YOUR TASK?</h2>
                        <p>Get complete your task now and increase your potential </p>
                    </div>
                    <CountDownTimer dateTime={formattedDateTime}/>
                    <div className='taskbutton'>
                        <Button className='custom-btn fill-btn shodow-btn' onClick={completeTask}>
                        yes I have Complete my task
                        </Button>
                    </div>
                </Modal.Body>                
            </Modal>
        </>
    );
}
export default TaskModal;