import React from 'react';
import './index.css';
import Toast from 'react-bootstrap/Toast';
import { Row, Col } from "react-bootstrap";

const NotificationsList = ({data, handleRemoveNotification})=>{
    let uniqueClass = `notification-${data.id}`
    return (
        <>
            <Row className={uniqueClass}>
                <Col md={12}>
                    <Toast show={true} onClose={()=>handleRemoveNotification(data.id)} className='notificationsmain my-1'>
                        <Toast.Header>
                            <div className='notifications-outer'>
                                <div className='notifications-inner'>
                                    <span></span>
                                    <Toast.Body>{data.notification_message}</Toast.Body>
                                </div>
                                <small>{data.notification_date}</small>
                            </div>
                        </Toast.Header>
                    </Toast>
                </Col>
            </Row>
        </>
    )
}
export default NotificationsList;