import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import NavbarTop from "../components/NavbarTop";
import CopyFooter from "../components/CopyFooter";
import { Link } from "react-router-dom";
 
const PageNotFound = () => {
    return (
        <>
            <NavbarTop />
                <section className='pagenotfound'>
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={12} md={8} lg={8}>
                                <div className='hero-text'>
                                    <Image src='/asset/images/404image.svg' alt="404image" />
                                    <h1>Page Not Found</h1>
                                    <Link className='custom-btn fill-btn shodow-btn' to={'/home'}>Back to home</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            <CopyFooter />
        </>
    );
};
 
export default PageNotFound;