import React,{useEffect, useState} from 'react';
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
import SideBar from '../components/Sidebar';
import { Container,Card, Row, Col } from "react-bootstrap";
import MyRewards from '../components/MyRewards';
import VipBoard from '../components/VipBoard';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../components/PageLoader';
import { fetchRewardDataAsync } from '../redux/auth/userSlice';
const Dashboard = () => {
    const [toggleClass, setToggleClass] = useState(false);
    const {loading} = useSelector((state)=>state.auth);
    const dispatch = useDispatch();
    const [rewardData, setRewardData] = useState([]);
    const [rating, setRating] = useState(0);
    useEffect(()=>{
        dispatch(fetchRewardDataAsync()).then((result)=>{
            const payload = result.payload;
            if(payload.status === true){
                setRewardData(payload.data.vip_users);
                setRating(payload.data.star);
            }
        })
    },[])
    return (
        <>
            {(loading) ? <PageLoader/>:''}
            <NavbarTop />
            <div className={toggleClass ? "maincontentouter opensidebar":"maincontentouter"}>
                <div className='leftsidebar'>
                    <SideBar setToggleClass={setToggleClass} toggleClass={toggleClass}/>
                </div>
                <div className='maincontent'>
                    <div className='dashboardtext'>
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    <MyRewards rating={rating}/>
                                    <h2 className="after-line">VIP board</h2>
                                    <Card className='vipcard'>                
                                        <Row className='row-gap-24'>
                                            {(rewardData.length>0) ? 
                                                rewardData.map((data, index) => (
                                                    <VipBoard data={data} key={index}/>    
                                            )):<p className="text-center">No Data Found</p>
                                            }
                                        </Row>   
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <CopyFooter />
                </div>
            </div>  
        </>
    );
}
export default Dashboard;