import React,{useState,useEffect} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
import NewsAndBlogs from '../components/NewsAndBlogs';
import RegisterForm from '../form/auth/RegisterForm';
import UpcommingSeminars from '../components/UpcomingSeminars';
import { fetchRegistrationsAsync,ResetStateAsync } from '../redux/auth/authSlice';
import { validationErrors } from '../request/errorHandler';
import { fetchLatestSeminarBlogAsync,fetchBannerDataAsync } from '../redux/auth/userSlice';
import PageLoader from '../components/PageLoader';
import GuestUserModal from '../components/GuestUserModal';

const Register = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [latestBlogSeminar, setLatestBlogSeminar] = useState('');
    const {loading, errors} = useSelector((state)=>state.auth);
    const {bannerloading} = useSelector((state)=>state.user);
    const validationErrorMessage = validationErrors(errors);
    const [isConfirmPasswordMatch, setIsConfirmPasswordMatch] = useState(false);
    const [bannerData,setBannerData] = useState('');
    const [modalShow, setModalShow] = useState('');
    const [seminarId,setSeminarId] = useState('');
    useEffect(()=>{
        // dispatch(ResetStateAsync());
        dispatch(fetchBannerDataAsync('register')).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setBannerData(payload.data);
            }
        })
    },[])
    const onSubmit = (e) => {
        e.preventDefault()
        // register user formData
        const formData = new FormData(e.target); 
        dispatch(fetchRegistrationsAsync({ registerData: formData })).then((result)=>{
            let response = result.payload;
            if(response.status === true){
                navigate("/");
            }else if(response.message === 'Request failed with status code 401'){
                dispatch(ResetStateAsync());
            }
        });
        
    };
    
    useEffect(()=>{
        dispatch(fetchLatestSeminarBlogAsync('login')).then((result)=>{
            if(result.payload.status === true){
                setLatestBlogSeminar(result.payload.data);
            }
        });
    },[])
    const bookSeats = (seminar) => {
        setSeminarId(seminar);
        setModalShow(true);
    }
    const { news, seminar } = latestBlogSeminar;
    return(
        <>
            <NavbarTop />
            {/* <HomeBanner bannerData={bannerData}/> */}
                <section className='login-news-sec  ptb-100'>
                    <Container>
                        <Row className="justify-content-between">
                            <Col sm={12} md={6}>
                                <RegisterForm onSubmit={onSubmit} isConfirmPasswordMatch={isConfirmPasswordMatch} loading={loading} validationErrorMessage={validationErrorMessage}/>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className='news-blog-outer-home'>
                                    <div className='titel-holder'>
                                        <h2>News & Blogs</h2>
                                    </div>
                                    { bannerloading ? <PageLoader/>:''}
                                    { Object.keys(latestBlogSeminar).length >0 ? 
                                        <>
                                            {seminar && <UpcommingSeminars data={seminar} bookSeats={bookSeats}/>}
                                            {
                                            (news) ? 
                                                news.map((data, index) => (
                                                    <NewsAndBlogs data={data} key={index} />
                                                )):''
                                            }
                                        </> : <p className="text-center">No Data Found</p>
                                    }
                                </div>                        
                            </Col>
                        </Row>
                        <GuestUserModal show={modalShow} setModalShow={setModalShow} seminarId={seminarId}/>
                    </Container>
                </section>
            <CopyFooter />
        </>
    );
}
export default Register;