import React,{useEffect, useState} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
import SideBar from '../components/Sidebar';
import NotificationsList from '../components/NotificationsList';
import PageLoader from '../components/PageLoader';
import { useDispatch,useSelector } from 'react-redux';
import { fetchNotificationDataAsync, deleteNotificationAsync } from '../redux/auth/userSlice';
import Pagination from '../components/Pagination';

const Notifications = () => {
    const {userLoading} = useSelector((state)=>state.user);
    const [toggleClass, setToggleClass] = useState(false);
    const [showNotification, setShowNotification] = useState(true);
    const [notificationsData, setNotificationData] = useState([]);
    const [totalRecord,setTotalRecord] = useState('');
    const [perPageRecord,setPerPageRecord] = useState('');
    const [activePageNumber,setActivePageNumber] = useState(1);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchNotificationDataAsync(activePageNumber)).then((result)=>{
            const payload = result.payload;
            if(payload.status === true){
                if(payload.data !== null){
                    setNotificationData(payload.data.data);
                    setTotalRecord(payload.data.total);
                    setPerPageRecord(payload.data.per_page);
                }
            }
        })
    },[activePageNumber])

    const handleRemoveNotification = (id) => {
        let object = {page:activePageNumber, id:id}
        dispatch(deleteNotificationAsync(object)).then((result)=>{
            const payload = result.payload;
            if(payload.status === true){
                if(payload.data === null){
                    if(activePageNumber >1){
                        setActivePageNumber(parseInt(activePageNumber)-1);
                    }else{
                        setNotificationData([]);
                    }
                }else{
                    setNotificationData(payload.data.data);
                    setTotalRecord(payload.data.total);
                    setPerPageRecord(payload.data.per_page);
                }
                // setShowNotification(!showNotification);
                // document.querySelector(`.notification-${id}`).remove();
            }
        })
    }

    return (
        <>
            {(userLoading) ? <PageLoader/>:''}
            <NavbarTop />
            <div className={toggleClass ? "maincontentouter opensidebar":"maincontentouter"}>
                <div className='leftsidebar'>
                    <SideBar setToggleClass={setToggleClass} toggleClass={toggleClass}/>
                </div>
                <div className='maincontent'>
                    <div className='dashboardtext'>
                        <Container>
                            <Row >
                                <Col sm={12} md={12} >                                    
                                    <h2 className='after-line'>Notifications</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} > 
                                    {notificationsData && notificationsData.length > 0 ? (
                                          notificationsData.map((data, index) => (
                                            <NotificationsList key={index} data={data} handleRemoveNotification={handleRemoveNotification} showNotification={showNotification}/>
                                          ))
                                    ) : <p className="text-center">No Data Found</p>}
                                    <Pagination activePageNumber={activePageNumber} setActivePageNumber={setActivePageNumber} totalRecord={totalRecord} perPageRecord={perPageRecord}/>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <CopyFooter />
                </div>
            </div>  
        </>
    );
}
export default Notifications;