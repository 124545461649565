import React, {useState} from 'react';
import './login.css';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardText,
  Form,
  FormLabel,
  FormControl,
  Col,
  Row,
  InputGroup,
  Button,
  Image
} from 'react-bootstrap';

const LoginForm = ({onSubmit,loading,validationErrorMessage,setEmail, email, setPassword, password,remember,setRemember})=>{
  const [showPassoword, setshowPassoword] = useState(false);
  const togglePasswordVisibility = () => {
    setshowPassoword(!showPassoword);
  };
    return (
      <>
        <div className='login-signup-outer'>
          <div className='titel-holder'>
              <h2>Login Now</h2>
              <p>Welcome Back! please enter your email and password.</p>
          </div>
          <Card className="mb-0 cardshadow">
            <CardBody>
                <CardText className="text-center navbarlogin">
                    <Link className='custom-btn fill-btn shodow-btn' to={'/'}>Login</Link>
                    <Link className='custom-btn light-color' to={'/register'}>Sign Up</Link>
                </CardText>
                <Form className='form-input' onSubmit={onSubmit}>
                  <Row>
                      <Col className='text-left'>
                          <Form.Group className="form-row">
                              <div className='position-relative'>
                                <FormLabel>Email</FormLabel>
                                <InputGroup.Text>
                                  <Image src='/asset/images/mail.svg' alt='mail' />
                                </InputGroup.Text>
                                <FormControl type="text"  placeholder="Enter Your Email" name="email" autoComplete='username' onChange={(e) => setEmail(e.target.value)} value={email}/>
                                </div>
                              <span className='error'>{validationErrorMessage.email !==undefined ? validationErrorMessage.email[0]:''}</span>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                      <Col className='text-left'>
                          <Form.Group className="form-row last-input">
                              <div className='position-relative'>
                                <FormLabel>Password</FormLabel>
                                <InputGroup.Text>
                                  <Image src='/asset/images/lock.svg' alt='lock' />
                                </InputGroup.Text>
                                <FormControl type={showPassoword ? "text" : "password"}  placeholder="Enter Your Password" name="password" autoComplete='current-password' onChange={(e) => setPassword(e.target.value)} value={password}/>
                                <span onClick={togglePasswordVisibility}
                                  className={`form-icon-password toggle-password`}
                                >
                                  <img
                                    src=
                                    {showPassoword ? "./asset/images/eye-off.svg" : "./asset/images/eye.svg"}
                                    className="img-fluid"
                                    alt="eye-icon"
                                  />
                                </span>
                                </div>
                              <span className='error'>{validationErrorMessage.password !==undefined ? validationErrorMessage.password:''}</span>
                          </Form.Group>

                      </Col>
                  </Row>
                  <Row className='form-row'>
                    <Col className='text-start'>
                        <Form.Group className="checkbox-c" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" label="Remember me" name="remember" onChange={(e) => setRemember(e.target.checked)} checked={remember}/>
                        </Form.Group>
                    </Col>
                    <Col className='text-end'>
                        <Form.Group className="forgotLink">
                            <Link to="/forgotpassword" className='text-primary link-underline-light text-secondary'> Forgot Password ?</Link>
                        </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="submit" className='custom-btn fill-btn shodow-btn w-100'>{loading ? 'loading..':'Login Now!'}</Button>
                    </Col>
                  </Row>
                </Form>
            </CardBody>
          </Card>
        </div>
      </>
    );
}
export default LoginForm;