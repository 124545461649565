import React from 'react';
import './newsandblog.css';
import { Image } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
const NewsAndBlogs = ({data}) => {
    return(
        <>
            <Card className='newscard'>
                <div className='cradimg overflow-hidden'>  
                    <Image className='h-100 w-100 object-fit-cover' src={data.image_url ? data.image_url :'/asset/images/no-image.jpg'} alt='card img'/>
                </div>
                <div className='cradtextouter d-flex flex-wrap'>
                    <div className='cradtext'>
                        <Card.Title>{data.title}</Card.Title>
                        <ListGroup as="ul">
                            <ListGroup.Item as="li">                                  
                                <Image src='/asset/images/user-1.svg' alt='card img'/> {data.created_by}
                            </ListGroup.Item>
                            <ListGroup.Item as="li">                                  
                                <Image src='/asset/images/calendar-dark.svg' alt='calendar-dark'/>  {data.datetime}
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className='cradlink'>
                        <Link className='custom-btn light-color-btn' to={"/news/details/"+data.slug}>Read More</Link>
                    </div>
                </div>
            </Card>
        </>
    )
}
export default NewsAndBlogs;