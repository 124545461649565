import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Image } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import './sidebar.css';
import { useDispatch,useSelector } from 'react-redux';
import { logoutUserAsync } from '../../redux/auth/authSlice';
import Swal from 'sweetalert2';
import UpcomingWebinar from '../UpcomingWebinar';

const SideBar = ({setToggleClass, toggleClass}) => {
    const {responseData} = useSelector((state)=>state.auth);
    const [upcomingSeminar, setUpcomingSeminar] = useState('');
    const location = useLocation();
    //destructuring pathname from location
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ConfirmBox = ()=>{
        Swal.fire({
        text: "Are you sure want to logout?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(logoutUserAsync()).then((result)=>{
                    if(result.payload.status){
                        navigate('/');
                        // window.location.href="/";
                    }
                })
            }
        });
    }
    const [isVisible, setIsVisible] = useState(true);
    useEffect(()=>{
        if(responseData !== '' && responseData !== null &&  responseData !== undefined)
        setUpcomingSeminar(responseData.data.closest_webinar_detail);
    },[responseData]);
    return (
        <>
            <div className='mobileiconsidebar' onClick={()=>setToggleClass(!toggleClass)}></div>
            <div className='mobileiconsidebar-inner'>
                <div className='dashboardprofile'>
                    <Card className='profilecard'>
                        <div className='dashboardprofileimg overflow-hidden'>
                            
                            <Card.Img className='h-100 w-100 object-fit-cover' variant="top" src={responseData!=null && responseData.data !== undefined ? responseData.data.profile_image ? responseData.data.profile_image:'/asset/images/user.png' : ''} alt='user'/>
                        </div>
                        <h4>Welcome Back!</h4>
                        <p className='usernamedash'>{responseData!=null && responseData.data !== undefined ? responseData.data.name:'' }</p>
                        <div className='userbadge'>
                            {/* <Badge><Image src='/asset/images/vip.svg' alt='vip' /> VIP</Badge> */}
                        </div>
                    </Card>
                </div>
                <div className='slidbarlinks'>
                    <Link  className={splitLocation[1] === "dashboard" ? "active" : ""} to='/dashboard'>
                        <span>
                            <Image src='/asset/images/dashboard-dark.svg' alt='dashboard-dark' />
                        </span>Dashboard
                    </Link>
                    <Link className={splitLocation[1] === "profilesetting" ? "active" : ""} to='/profilesetting'>
                        <span>
                            <Image src='/asset/images/profile-setting-dark.svg' alt='profile-setting-dark' />
                        </span>Profile Settings
                    </Link>
                    <Link className={splitLocation[1] === "webinar" ? "active" : ""} to='/webinar'>
                        <span>
                            <Image src='/asset/images/webinar-dark.svg' alt='webinar-dark' />
                        </span>Webinar
                    </Link>
                    <Link className={splitLocation[1] === "notifications" ? "active" : ""} to='/notifications'>
                        <span>
                            <Image src='/asset/images/notification-bing.svg' alt='notifications' />
                        </span>Notifications
                    </Link>
                    <Link className={splitLocation[1] === "rewards" ? "active" : ""} to='/rewards'>
                        <span>
                            <Image src='/asset/images/crown.svg' alt='crown' />
                        </span>Rewards
                    </Link>
                    <Link className='nav-link' onClick={ConfirmBox}>
                        <span>
                            <Image src='/asset/images/logout-dark.svg' alt='logout-dark' />
                        </span>Logout
                    </Link>
                </div>
                {upcomingSeminar !==null && upcomingSeminar !='' &&
                <div className='sidebarwebnar' style={{ display: isVisible ? "flex" : "none", }}>
                    <div className='closetask' onClick={() => setIsVisible(false)}></div>
                    <UpcomingWebinar data={upcomingSeminar}/>
                </div>
                }
            </div>
        </>
    );
}
export default SideBar;