import React, { useEffect, useState } from 'react';
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
import SideBar from '../components/Sidebar';
import WebinarComponent from '../components/WebinarComponent';
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { fetchWebinarAsync } from '../redux/auth/userSlice';
import Pagination from '../components/Pagination';
import PageLoader from '../components/PageLoader';

const Webinar = () => {
    const [toggleClass, setToggleClass] = useState(false);
    const dispatch = useDispatch();
    const [webinarData, setWebinarData] = useState('');
    const [totalRecord,setTotalRecord] = useState('');
    const [perPageRecord,setPerPageRecord] = useState('');
    const [activePageNumber,setActivePageNumber] = useState(1);
    const { auth: {loading }, user: {userLoading }} = useSelector((state)=>state);


    useEffect(()=>{
        dispatch(fetchWebinarAsync(activePageNumber)).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setWebinarData(payload.data.data);
                setTotalRecord(payload.data.total);
                setPerPageRecord(payload.data.per_page);
            }
        })
    },[activePageNumber]);
    return (
        <>
            {(loading) ? <PageLoader/>:''}
            <NavbarTop />
            <div className={toggleClass ? "maincontentouter opensidebar":"maincontentouter"}>
                <div className='leftsidebar'>
                    <SideBar setToggleClass={setToggleClass} toggleClass={toggleClass}/>
                </div>
                <div className='maincontent'>
                    <div className='dashboardtext position-relative'>
                    { userLoading ? <div className='header-loader'> <span className="fa fa-spinner fa-spin m-2"/></div>:''}
                        <Container>
                            <Row >
                                <Col sm={12} md={12} >                                    
                                    <h2 className='after-line'>Our Webinars</h2>
                                </Col>
                            </Row>
                            <Row className='row-gap-24'>
                                {
                                (webinarData) ? 
                                webinarData.map((data, index) => (
                                    <WebinarComponent data={data} key={index} />
                                )):<p className="text-center">No Data Found</p>
                                }
                                <Pagination activePageNumber={activePageNumber} setActivePageNumber={setActivePageNumber} totalRecord={totalRecord} perPageRecord={perPageRecord}/>
                            </Row>
                        </Container>
                    </div>
                    <CopyFooter />
                </div>
            </div>            
        </>
    );
}
export default Webinar;