import React from 'react';
import './index.css';
import { Image, ListGroup } from 'react-bootstrap';

const DailyTask = ({taskTrackingData})=>{
    const {week_days} = taskTrackingData;
    let todayDays = 0;
     if(week_days !== undefined){
        for(let data in week_days){
            if(week_days[data] === null){
                todayDays = data-1;
                break;
            }
        }
     }
    return (
        <>
            <div className='trakglassouter'>
                <div className='smallglass'>
                    <ListGroup>
                    {week_days !== undefined ? Object.entries(week_days).map(([key, value]) => (
                        <ListGroup.Item key={key}>
                            <Image src={value===true ? '/asset/images/glass.svg': value === false ? '/asset/images/fillglass.svg' : '/asset/images/fillglass.svg'} alt='glass img'/></ListGroup.Item>
                    )):''}
                    </ListGroup>
                </div>
                <div className='lagreglass'>
                    <div className='lagreglassimg'>
                        <Image src='/asset/images/largeglass.svg' alt='largeglass'/>
                    </div>
                    <ListGroup>
                        <div className={'glassscalewaterfill day'+todayDays}></div>
                        {week_days !== undefined ? Object.entries(week_days).map(([key, value]) => (
                             <ListGroup.Item className={value === false ? 'taskmiss':''} key={key}></ListGroup.Item>
                        )):''}
                    </ListGroup>
                </div>
                <div className='glassscale'>
                    <ListGroup>
                        {week_days !== undefined ? Object.entries(week_days).map(([key, value]) => (
                            <ListGroup.Item  key={key} className={value === true && value !==null ? 'taskactive':''}><span>Day {key}</span></ListGroup.Item>
                        )):''}
                        {/* <ListGroup.Item><span>Day 7</span></ListGroup.Item>
                        <ListGroup.Item><span>Day 6</span></ListGroup.Item>
                        <ListGroup.Item className='taskactive'><span>Day 5</span></ListGroup.Item>
                        <ListGroup.Item><span>Day 4</span></ListGroup.Item>
                        <ListGroup.Item className='taskactive'><span>Day 3</span></ListGroup.Item>
                        <ListGroup.Item className='taskactive'><span>Day 2</span></ListGroup.Item>
                        <ListGroup.Item className='taskactive'><span>Day 1</span></ListGroup.Item> */}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}
export default DailyTask;