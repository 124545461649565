import axios from 'axios';
import successHandler from '../successHandler';
import {validationErrors as errorHandler} from '../errorHandler';
import { HelperFunction } from '../../util/Helper'; 
const API_BASE_URL = process.env.REACT_APP_API_URL;
const {getlocalStorageData} = HelperFunction();

export const fetchSeminars = async (page) => {
    try {
        const response = await axios.get(API_BASE_URL + `get-all-seminars?page=${page}`);
        const { data } = response;
        // await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const fetchPosts = async (page) => {
    try {
        const response = await axios.get(API_BASE_URL + `get-posts/${page.postType}?page=${page.activePageNumber}`);
        const { data } = response;
        // await successHandler(response);
        return data;
    } catch (error) {   
        await errorHandler(error.response);
        return error;
    }
}
export const fetchWebinars = async (page) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };

        const response = await axios.get(API_BASE_URL + `get-all-webinars?page=${page}`,{headers:headers});
        const { data } = response;
        // await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const fetchLatestRecord = async (page) => {
    try {
        const response = await axios.get(API_BASE_URL + `get-latest-records/${page}`);
        const { data } = response;
        // await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const fetchSingleNewsDetails = async (slug) => {
    try {
        const response = await axios.get(API_BASE_URL + `post/${slug}`);
        const {  data } = response;
        // await successHandler(response);
        return data;
    } catch (error) {   
        await errorHandler(error.response);
        return error;
    }
}

export const fetchBannerData = async (page) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };

        const response = await axios.get(API_BASE_URL + `page/${page}`,{headers:headers});
        const {  data } = response;
        // await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const fetchQuoteOfTheDay = async () => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };

        const response = await axios.get(API_BASE_URL + `dashboard`,{headers:headers});
        const { data } = response;
        // await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const fetchReward = async () => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };

        const response = await axios.get(API_BASE_URL + `reward`,{headers:headers});
        const { data } = response;
        // await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const fetchNotification = async (page) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };

        const response = await axios.get(API_BASE_URL + `notifications?page=${page}`,{headers:headers});
        const { data } = response;
        // await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const fetchSeminarTicketDetails = async (ticketData) => {
    const response = await axios.post(API_BASE_URL + `checkout-success`,ticketData);
    const { data } = response;
    // await successHandler(response);
    return data;
}

export const updateUserProfile = async (updatedData) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };
        const response = await axios.post(API_BASE_URL + `update-profile`,updatedData,{headers:headers});
        const {  data } = response;
        await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}

export const updateUserProfileImage = async (updatedImage) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
            "Content-Type": "multipart/form-data",
        };
        const response = await axios.post(API_BASE_URL + `update-profile-image`,updatedImage,{headers:headers});
        const {  data } = response;
        await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}

export const changePassword = async (updatedData) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };

        const response = await axios.post(API_BASE_URL + `update-password`,updatedData,{headers:headers});
        const { data } = response;
        await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}

export const wantBreak = async (updatedData) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };

        const response = await axios.post(API_BASE_URL + `update-user-status`,updatedData,{headers:headers});
        const { data } = response;
        await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const contactUs = async (confactData) => {
    try {
        const response = await axios.post(API_BASE_URL + `contact-us`,confactData,{});
        const { data } = response;
        await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const completeTask = async (id) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };
        const response = await axios.post(API_BASE_URL + `completed-task`,{quote_id:id},{headers:headers});
        const {data} = response;
        await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const bookSeat = async (userData) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };
        let response = await axios.post(API_BASE_URL + `checkout-session`,userData,{headers:headers});
        const {data} = response;
        await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}
export const deleteNotification = async (pageData) => {
    try {
        let headers = {
            Accept: "application/json",
            Authorization: "Bearer " + getlocalStorageData().access_token,
        };
        const {page,id} = pageData;
        const response = await axios.delete(API_BASE_URL + `notification/${id}?page=${page}`,{headers:headers});
        const {data} = response;
        await successHandler(response);
        return data;
    } catch (error) {
        await errorHandler(error.response);
        return error;
    }
}