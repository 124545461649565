import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Form,
  FormLabel,
  FormControl,
  Col,
  Row,
  InputGroup,
  Button,
  Image
} from 'react-bootstrap';

const ConfirmPasswordForm = ({onSubmit,isConfirmPasswordMatch,loading,validationErrorMessage})=>{

  const [showPassoword, setshowPassoword] = useState(false);
  const togglePasswordVisibility = () => {
    setshowPassoword(!showPassoword);
  };
  const [confirmPassoword, setconfirmPassoword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setconfirmPassoword(!confirmPassoword);
  };
    return (
      <>
        <div className='login-signup-outer'>
          <div className='titel-holder'>
              <h2>Set Your Password</h2>
              <p>Welcome Back! please enter your email and password.</p>
          </div>
          <Card className="mb-0">
            <CardBody>
                <Form className='form-input' onSubmit={onSubmit}>
                  <Row>
                      <Col className='text-start'>
                          <Form.Group className="form-row last-input">
                              <div className='position-relative'>
                                <FormLabel>New Password</FormLabel>
                                <InputGroup.Text>
                                  <Image src='/asset/images/lock.svg' alt='lock' />
                                </InputGroup.Text>
                                <FormControl type={showPassoword ? "text" : "password"}  placeholder="Enter New Password" name="password"/>
                                <span onClick={togglePasswordVisibility}
                                  className={`form-icon-password toggle-password ${
                                    showPassoword ? "eye-open" : "eye-close"
                                  }`}
                                >
                                  <img
                                    src=
                                    {showPassoword ? "/asset/images/eye-off.svg" : "/asset/images/eye.svg"}
                                    className="img-fluid"
                                    alt="eye-icon"
                                  />
                                </span>
                                </div>
                              <span className='error'>{validationErrorMessage.password !==undefined ? validationErrorMessage.password:''}</span>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                      <Col className='text-start'>
                          <Form.Group className="form-row last-input">
                            <div className='position-relative'>
                              <FormLabel>Confirm New Password</FormLabel>
                              <InputGroup.Text>
                                <Image src='/asset/images/lock.svg' alt='lock' />
                              </InputGroup.Text>
                              <FormControl type={confirmPassoword ? "text" : "password"}  placeholder="Confirm Your Password" name="password_confirmation"/>
                              <span onClick={toggleConfirmPasswordVisibility}
                                className={`form-icon-password toggle-password ${
                                  confirmPassoword ? "eye-open" : "eye-close"
                                }`}
                              >
                                <img
                                  src=
                                  {confirmPassoword ? "/asset/images/eye-off.svg" : "/asset/images/eye.svg"}
                                  className="img-fluid"
                                  alt="eye-icon"
                                />
                              </span>
                            </div>
                              <span className='error'>{validationErrorMessage.password_confirmation !==undefined ? validationErrorMessage.password_confirmation:''}</span>
                          </Form.Group>
                          <span className="text-danger"><small>{isConfirmPasswordMatch ? 'Confirm password not matched with new password':''}</small></span>
                      </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button className='custom-btn fill-btn shodow-btn w-100' type="submit">{loading ? 'Loading...':'Save'}</Button>
                    </Col>
                  </Row>
                </Form>
            </CardBody>
          </Card>
        </div>
      </>
    );
}
export default ConfirmPasswordForm;