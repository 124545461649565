import React from 'react';
import './index.css';
import { Image, ListGroup } from 'react-bootstrap';

const WeeklyTask = ({taskTrackingData})=>{
    const {completed_week } = taskTrackingData;
    const weeklyData = Array(9).fill(1);

    return (
        <>
            <div className='trakglassouter weeklytask'>
                <div className='lagreglass'>
                    <div className='lagreglassimg'>
                        <Image src='/asset/images/largeglass.svg' alt='largeglass'/>
                    </div>
                    <ListGroup>
                        <div className={'glassscalewaterfill week'+completed_week}></div>
                    </ListGroup>
                </div>
                <div className='glassscale'>
                    <ListGroup>
                        {weeklyData.map((data,index)=>{
                             return(<ListGroup.Item className={index+1 == completed_week ? 'taskactive':''} key={index}><span>Week {index+1}</span></ListGroup.Item>)
                        })}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}
export default WeeklyTask;