import React, { useState, useEffect } from 'react';
import './index.css'; // Import your CSS if needed
import Countdown from 'react-countdown';

const CountDownTimer = ({ dateTime,endTime }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const webinarStartTime = new Date(dateTime).getTime();
  const webinarExpiryTime = new Date(endTime).getTime();

  const targetTimeInMilliseconds =  dateTime !== undefined ? dateTime : webinarStartTime; ; // Use dateTime prop if provided, otherwise default to start time

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
  const isOngoing = currentTime.getTime() >= webinarStartTime && currentTime.getTime() < webinarExpiryTime;
  const isExpired = currentTime.getTime() >= webinarExpiryTime;

    // Update event status based on current time and set completed state manually
    if (isExpired) {
      return  <span id="expired-web" className='exp expired-web'><span className='webnairexpiredtext'>EXPIRED WEBINAR</span></span>;
    } else if (isOngoing) {
      return <span className='webnairexpiredtext'>Ongoing</span>;
    } else {
      return (
        <div className="timer text-center">
          {days > 0 && <><span>{days < 10 ? 0 : ''}{days} <span>Days</span></span>:</>}
          <span>{hours < 10 ? 0 : ''}{hours} <span>hours</span></span>:
          <span>{minutes < 10 ? 0 : ''}{minutes} <span>minutes</span></span>:
          <span>{seconds < 10 ? 0 : ''}{seconds} <span>seconds</span></span>
        </div>
      );
    }
  };

  return (
    <Countdown
      date={targetTimeInMilliseconds}
      renderer={renderer}
    />
  );
};

export default CountDownTimer;