import React from 'react';
import { Col, ListGroup, Card, Image } from "react-bootstrap";
import { Link } from 'react-router-dom';
import './index.css';
import CountDownTimer from '../CountDownTimer';
const WebinarComponent = ({data}) => {
    let startTime = data.start_date +' '+ data.start_time;
    let endTime = data.start_date +' '+ data.end_time;
    // setTimeout(() => {
    //     var expiredElements = document.querySelectorAll('.expired-web');
    //     expiredElements.forEach(function(expiredElement) {
    //         var parentCardElement = expiredElement.closest('.webnarcard');
    //         if (parentCardElement) {
    //             parentCardElement.classList.add('webnarexpired');
    //         }
    //     });
    // }, 400);
    return (
        <>
            <Col sm={12} md={6} lg={6}>
                <Card className='webnarcard'>
                    <div className='cradimg overflow-hidden'>  
                        <Image className='h-100 w-100 object-fit-cover' src={data.image_url ? data.image_url: '/asset/images/no-image.jpg'} alt='card img'/>
                    </div>
                    <div className='cradtextouter'>
                        <div className='cradtext'>
                            <Card.Title>{data.title}</Card.Title>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li" className='meteor'>                                  
                                    <Image src='/asset/images/calendar.svg' alt='calendar'/> {data.datetime}
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                        <div className='cradlink'>
                            <CountDownTimer dateTime={startTime} endTime={endTime}/>
                            <Link className='custom-btn fill-btn shodow-btn stretched-link' to={data.meeting_link} target='_blank'>Join The Webinar</Link>
                        </div>
                    </div>
                </Card>
            </Col>

        </>
    );
}
export default WebinarComponent;