import React, { useState } from 'react';
import './index.css';
import { Image, Card } from "react-bootstrap";
import { Link } from 'react-router-dom';
const MyTask = ({completeTask}) => {
    const [isVisibleTask, setIsVisibleTask] = useState(true);
    const handleClose = () => {
        setIsVisibleTask(false);
        completeTask();
    }
    return(
        <>
            <Card className='mytask' style={{ display: isVisibleTask ? "flex" : "none", backgroundImage: `url('/asset/images/cardbg.jpg')`, backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat" }}>
                <div className='closetask' onClick={() => setIsVisibleTask(false)}></div>
                <div className='mytask-text align-self-center'>
                    <h1>Did you complete your task?</h1>
                    <Link className='custom-btn fill-btn shodow-btn' onClick={handleClose}>yes I have Completed my task</Link>
                </div>
                <div className='mytask-img'>                           
                    <Image src='/asset/images/taskright.png' alt='taskright'/>
                </div>
            </Card>
        </>
    )
}
export default MyTask;