import React,{useEffect,useState} from 'react';
import { Container, Row } from "react-bootstrap";
import NavbarTop from '../components/NavbarTop';
import OtherPgaeBanner from '../components/OtherPagesBanner';
import CopyFooter from '../components/CopyFooter';
import NewsBlogs from '../components/NewsBlogs';
import { fetchBannerDataAsync, fetchPostsAsync } from '../redux/auth/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../components/Pagination';

const Blog = () => {
    const dispatch = useDispatch();
    const [blogNewsData, setBlogNewsData] = useState('');
    const [bannerData, setBannerData] = useState('');
    const [totalRecord,setTotalRecord] = useState('');
    const [perPageRecord,setPerPageRecord] = useState('');
    const [activePageNumber,setActivePageNumber] = useState(1);
    useEffect(()=>{
        dispatch(fetchPostsAsync({activePageNumber:activePageNumber,postType:'news'})).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setBlogNewsData(payload.data.data);
                setTotalRecord(payload.data.total);
                setPerPageRecord(payload.data.per_page);
            }
        })
    },[activePageNumber]);
    
    useEffect(()=>{
        dispatch(fetchBannerDataAsync('news')).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setBannerData(payload.data);
            }
        })
    },[]);
    const {userLoading} = useSelector((state)=>state.user);
    return (
        <>
            <NavbarTop />
            <OtherPgaeBanner bannerData={bannerData}/>
            <section className='blog-sec position-relative ptb-100 position-relative'>
                { userLoading ? <div className='header-loader'> <span className="fa fa-spinner fa-spin m-2"/></div>:''}
                <Container>
                    <Row className='row-gap-24'>
                        {(blogNewsData.length>0) ? 
                            blogNewsData.map((data, index) => (
                                <NewsBlogs data={data} key={index} type="news"/>
                        )):<p className="text-center">No Data Found</p>}
                        <Pagination activePageNumber={activePageNumber} setActivePageNumber={setActivePageNumber} totalRecord={totalRecord} perPageRecord={perPageRecord}/>
                    </Row>
                </Container>
            </section>
            <CopyFooter />
        </>
    );
}
export default Blog;