import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
 
const PageExpired = () => {
    return (
        <>
        <section className='pagenotfound'>
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={8}>
                        <div className='hero-text'>
                            <Image src='/asset/images/419.svg' alt="419image" />
                            <h1>Page Expired</h1>
                            <Link className='custom-btn fill-btn shodow-btn' to={'/home'}>Back to home</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
};
 
export default PageExpired;