import React from 'react'
import { Container, Row, Col, Image } from "react-bootstrap";
import NavbarTop from "../components/NavbarTop";
import CopyFooter from "../components/CopyFooter";
import { Link } from "react-router-dom";

const PaymentFailed = () => {
  return (
    <>
        <NavbarTop />
        <section className='paymentpages' style={{backgroundImage: `url('/asset/images/paymentbg.png')`, backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat" }}>
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={8}>
                        <div className='paymentpages-inner text-center'>
                            <Image src='/asset/images/paymentfailed.png' alt="paymentfailed" />
                            <h1>Payment Cancel</h1>
                            {/* <p>Lorem Empowering individuals to fulfill their full potential and live their dreams.</p> */}
                            <Link className='custom-btn fill-btn shodow-btn' to={'/home'}>
                            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.8574 6H1.14314" stroke="" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.14258 1L1.14258 6L6.14258 11" stroke="" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Back To Home</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <CopyFooter />
    </>
  )
}

export default PaymentFailed;
