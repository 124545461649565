import React from 'react';
import './auth/login.css';
import {
  Form,
  FormLabel,
  FormControl,
  Col,
  Row,
  InputGroup,
  Button,
  Image
} from 'react-bootstrap';

const ContactForm = ({onSubmit,validationErrorMessage,userLoading})=>{
    return (
      <>
        <Form className='form-input' onSubmit={onSubmit}>
            <Row>
                <Col sm={6} md={6} lg={6}>
                    <Form.Group className="form-row">
                        <div className='position-relative'>
                            <FormLabel>First Name</FormLabel>
                            <InputGroup.Text>
                            <Image src='/asset/images/user.svg' alt='user' />
                            </InputGroup.Text>
                            <FormControl type="text"  placeholder="Enter Your First Name" name="first_name"/>
                        </div>
                        <span className='error'>{validationErrorMessage.first_name !==undefined ? validationErrorMessage.first_name[0]:''}</span>
                    </Form.Group>
                </Col>
                <Col sm={6} md={6} lg={6}>
                    <Form.Group className="form-row">
                        <div className='position-relative'>
                            <FormLabel>Last Name</FormLabel>
                            <InputGroup.Text>
                            <Image src='/asset/images/user.svg' alt='user' />
                            </InputGroup.Text>
                            <FormControl type="text"  placeholder="Enter Your Last Name" name="last_name"/>
                        </div>
                        <span className='error'>{validationErrorMessage.last_name !==undefined ? validationErrorMessage.last_name[0]:''}</span>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={6} md={6} lg={6}>
                    <Form.Group className="form-row">
                        <div className='position-relative'>
                            <FormLabel>Phone Number</FormLabel>
                            <InputGroup.Text>
                            <Image src='/asset/images/call-icon.svg' alt='call-icon' />
                            </InputGroup.Text>
                            <FormControl type="number"  placeholder="Enter Your Phone Number" name="phone_number"/>
                        </div>
                        <span className='error'>{validationErrorMessage.phone_number !==undefined ? validationErrorMessage.phone_number[0]:''}</span>
                    </Form.Group>
                </Col>
                <Col sm={6} md={6} lg={6}>
                    <Form.Group className="form-row">
                        <div className='position-relative'>
                            <FormLabel>Email</FormLabel>
                            <InputGroup.Text>
                            <Image src='/asset/images/mail.svg' alt='mail' />
                            </InputGroup.Text>
                            <FormControl type="email"  placeholder="Enter Your Email Address" name="email"/>
                        </div>
                        <span className='error'>{validationErrorMessage.email !==undefined ? validationErrorMessage.email[0]:''}</span>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                <Form.Group className="form-row" controlId="exampleForm.ControlTextarea1">
                    <div className='position-relative'>
                        <Form.Label>Message Here</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter Your Message" name="message"/>
                    </div>
                    <span className='error'>{validationErrorMessage.message !==undefined ? validationErrorMessage.message[0]:''}</span>
                </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button type="submit"  className='custom-btn fill-btn shodow-btn' >{userLoading ? 'Loading...':'Submit'}</Button>
                </Col>
            </Row>
        </Form>
      </>
    );
}
export default ContactForm;