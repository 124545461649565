import React from 'react';
import './newsblog.css';
import { Col } from "react-bootstrap";
import { Image } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
const NewsBlogs = ({data, type}) => {
    return(
        <>
            <Col sm={6} md={4} lg={4}>
                <Card className='blogcard'>
                    <div className='cradimg  overflow-hidden'>  
                        <Image className='h-100 w-100 object-fit-cover' src={data.image_url ? data.image_url: '/asset/images/no-image.jpg'} alt='card img'/>
                    </div>
                    <div className='cradtextouter d-flex flex-wrap'>
                        <div className='cradtext'>
                            <Card.Title>{data.title}</Card.Title>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li">                                  
                                    <Image src='/asset/images/user-1.svg' alt='user-1'/>  {data.created_by}
                                </ListGroup.Item>
                                <ListGroup.Item as="li">                                  
                                    <Image src='/asset/images/calendar-dark.svg' alt='calendar-dark'/>  {data.publish_date}
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                <span dangerouslySetInnerHTML={{ __html: data.content }} />
                            </Card.Text>
                        </div>
                        <div className='cradlink'>
                            <Link className='custom-btn light-color-btn w-100 text-center stretched-link' to={type==='news' ? "/news/details/"+data.slug : "/health/details/"+data.slug}>Read More</Link>
                        </div>
                    </div>
                </Card>
            </Col>
        </>
    )
}
export default NewsBlogs;