import React from 'react';
import './index.css';
import { Image } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CountDownTimer from '../CountDownTimer';
const UpcomingWebinar = ({data}) => {
    let startTime = data.start_date +' '+ data.start_time;
    let endTime = data.start_date +' '+ data.end_time;
    return(
        <>
            <Card className='upcomingwebinarcard'>
                <CountDownTimer dateTime={startTime} endTime={endTime}/>
                <div className='cradimg overflow-hidden'>  
                    <Image className='h-100 w-100 object-fit-cover' src={(data !==undefined) ? (data.image_url) ? data.image_url:'/asset/images/no-image.jpg' : '/asset/images/Rectangle.svg'}  alt='card img'/>
                </div>
                <div className='cradtextouter d-flex flex-wrap'>
                    <div className='cradtext'>
                        <Card.Title>{(data !==undefined) ? data.title : 'True You Club Realised Your Potential'}</Card.Title>
                        <ListGroup as="ul">
                            {/* <ListGroup.Item as="li" className='meteor'>                                  
                                <Image src='/asset/images/location.svg' alt='list icon'/> {(data !==undefined) ? data.venue : 'Gotham Hall, 1356 Broadway, USA'} 
                            </ListGroup.Item> */}
                            <ListGroup.Item as="li">                                  
                                <Image src='/asset/images/calendar-dark.svg' alt='list icon'/> {(data !==undefined) ? data.datetime : ' 10 February 2024 09:00AM EST'} 
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className='cradlink'>
                        <Link className='custom-btn fill-btn shodow-btn' to={"/webinar"}>Upcoming Webinar</Link>
                    </div>
                </div>
            </Card>
        </>
    )
}
export default UpcomingWebinar;