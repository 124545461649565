// authSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as services from '../../request/auth'

// Define the initial state
const initialState = {
  user: null,
  status: '', 
  isAuthenticated: false,
  loading: '',
  error: null,
  responseData: null,
  siteSetting:'',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // register cases
    .addCase(fetchRegistrationsAsync.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    })
    .addCase(fetchRegistrationsAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.status = 'fulfilled';
      state.user = result;
      state.loading = false;
      if(result.message ==="Request failed with status code 401" || result.message ==="Request failed with status code 422"){
        state.errors = result.response.data;
      }else{
        state.isAuthenticated = true;
        state.responseData = result;
      }
    })
    .addCase(fetchRegistrationsAsync.rejected, (state, action) => {
      state.status = 'rejected';
      state.user = action.payload;
    })

    // login cases
    .addCase(fetchLoginAsync.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    })
    .addCase(fetchLoginAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.status = 'fulfilled';
      state.loading = false;
      state.user = result.userData;
      state.access_token = result.access_token; 
      if(result.message ==="Request failed with status code 401" || result.message ==="Request failed with status code 422"){
        state.errors = result.response.data;
      }else{
        state.isAuthenticated = true;
        state.responseData = result;
      }
    })
    .addCase(fetchLoginAsync.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      
      if(action.error.message ==="Request failed with status code 401"){
        state.error = "Access Denied !";
      }
      return state; 
    })

      // verify Email cases
      .addCase(fetchVerifyEmailAsync.pending, (state) => {
        state.status = 'pending';
        state.loading = true;
      })
      .addCase(fetchVerifyEmailAsync.fulfilled, (state, action) => {
        let result = action.payload;
        state.status = 'fulfilled';
        state.loading = false;
        state.user = result.userData;
        state.access_token = result.access_token; 
        if(result.message ==="Request failed with status code 401" || result.message ==="Request failed with status code 422"){
          state.errors = result.response.data;
        }else{
          state.isAuthenticated = true;
          state.responseData = result;
        }
      })
      .addCase(fetchVerifyEmailAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.loading = false;
        
        if(action.error.message ==="Request failed with status code 401"){
          state.error = "Access Denied !";
        }
        return state; 
      })
      
    // Forgot Password
    .addCase(fetchForgotPasswordAsync.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    })
    .addCase(fetchForgotPasswordAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.status = 'fulfilled';
      state.user = result;
      state.loading = false;
      if(result.message ==="Request failed with status code 401" || result.message ==="Request failed with status code 422"){
        state.errors = result.response.data;
      }else{
        state.isAuthenticated = true;
        state.responseData = result;
      }
    })
    .addCase(fetchForgotPasswordAsync.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false; 
      
      if(action.error.message ==="Request failed with status code 401"){
        state.error = "Access Denied !";
      }
      return state;
    })

    // Reset Password
    .addCase(fetchResetPasswordAsync.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
    })
    .addCase(fetchResetPasswordAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.status = 'fulfilled';
      state.loading = false;
      state.user = result.userData;
      state.access_token = result.access_token; 
      if(result.message ==="Request failed with status code 401" || result.message ==="Request failed with status code 422"){
        state.errors = result.response.data;
      }else{
        state.isAuthenticated = true;
        state.responseData = result;
      }
    })
    .addCase(fetchResetPasswordAsync.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false; 
      
      if(action.error.message ==="Request failed with status code 401"){
        state.error = "Access Denied !";
      }
      return state;
    })
    // get logged in user details
    .addCase(fetchUserDetailsAsync.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.errors = '';
    })
    .addCase(fetchUserDetailsAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.status = 'fulfilled';
      state.loading = false;
      state.user = result.userData;
      state.access_token = result.access_token; 
      state.errors = '';
      if(result.message ==="Request failed with status code 401"){
        state.responseData = '';
        state.isAuthenticated = false;
      }else{
        state.isAuthenticated = true;
        state.responseData = result;
      }
    })
    .addCase(fetchUserDetailsAsync.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false; 
      state.isAuthenticated = false;
      if(action.error.message ==="Request failed with status code 401"){
        state.error = "Access Denied !";
      }
      return state;
    })

    // site setting
    .addCase(fetchSiteSettingAsync.pending, (state) => {
      state.status = 'pending';
      state.loading = true;
      state.errors = '';
    })
    .addCase(fetchSiteSettingAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.status = 'fulfilled';
      state.loading = false;
      if(result.status ===true){
        state.siteSetting = result.data;
      }
    })
    .addCase(fetchSiteSettingAsync.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false; 
      if(action.error.message ==="Request failed with status code 401"){
        state.error = "Access Denied !";
      }
      return state;
    })
    // logout user
    .addCase(logoutUserAsync.pending, (state, action) => {
      state = initialState;
      return state;
    })
    .addCase(logoutUserAsync.fulfilled, (state, action) => {
      state = initialState;
      return state;
    })

    // reset state
    .addCase(ResetStateAsync.pending, (state, action) => {
      state = initialState;
      return state;
    })
    .addCase(ResetStateAsync.fulfilled, (state, action) => {
      state = initialState;
      return state;
    })
  },
});


export const fetchLoginAsync = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {
  try {
    const response = await services.login(data);
    return response;
  } catch (error) {
    return rejectWithValue(error.response || error);
  }
});

export const fetchVerifyEmailAsync = createAsyncThunk('auth/verifyEmail', async (data, { rejectWithValue }) => {
  try {
    const response = await services.verifyEmail(data);
    return response;
  } catch (error) {
    return rejectWithValue(error.response || error);
  }
});

export const fetchRegistrationsAsync = createAsyncThunk('auth/register', async (data) => {
  return await services.register(data);
});

export const fetchForgotPasswordAsync = createAsyncThunk('auth/forgotpassword', async (data) => {
  return await services.forgotPassword(data);
});

export const fetchResetPasswordAsync = createAsyncThunk('auth/resetpassword', async (data) => {
  return await services.resetUserPassword(data);
});

export const fetchUserDetailsAsync = createAsyncThunk('auth/details', async () => {
  return await services.getUserDetails();
});

export const fetchSiteSettingAsync = createAsyncThunk('auth/siteSetting', async () => {
  return await services.getSiteSetting();
});

export const logoutUserAsync = createAsyncThunk('auth/logout', async (data) => {
  return await services.logoutUser(data);
});

export const ResetStateAsync = createAsyncThunk('auth/resetstate', async () => {
  return '';
});


export const { userRegistered } = authSlice.actions;
export default authSlice.reducer;

export const getAuth = (state) => state.auth.user