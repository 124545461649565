import CryptoJS from "crypto-js";
export const HelperFunction = () => {
    const secretPass = "XkhZG4fW2t2W";
    const encryptData = (text) => {
        const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(text),
        secretPass
        ).toString();
        return encryptedData;
    };
    const decryptData = (text) => {
        const bytes = CryptoJS.AES.decrypt(text, secretPass);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    };
    const getlocalStorageData = ()=>{
        let localStorageData = localStorage.getItem("user_data");
        let decryptDatas = "";
        if (localStorageData !== null) {
          decryptDatas = decryptData(localStorageData);
        }
        return decryptDatas;
    }
    const setlocalStorageData = (values)=>{
        let encryptUserData = encryptData(values);
        localStorage.setItem("user_data", encryptUserData);
        return true;
    }

    return {
        encryptData,
        decryptData,
        getlocalStorageData,
        setlocalStorageData,
      };
}