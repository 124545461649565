import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import NavbarTop from '../components/NavbarTop';
import OtherPgaeBanner from '../components/OtherPagesBanner';
import CopyFooter from '../components/CopyFooter';
import NewsBlogs from '../components/NewsBlogs';
import Pagination from '../components/Pagination';
import { useDispatch,useSelector } from 'react-redux';
import { fetchBannerDataAsync, fetchPostsAsync } from '../redux/auth/userSlice';
const Health = () => {
    const dispatch = useDispatch();
    const [bannerData,setBannerData] = useState('');
    const [healthData, setHealthData] = useState('');
    const [totalRecord,setTotalRecord] = useState('');
    const [perPageRecord,setPerPageRecord] = useState('');
    const [activePageNumber,setActivePageNumber] = useState(1);
    const {userLoading} = useSelector((state)=>state.user);
    // const { auth: {loading }, user: {userLoading }} = useSelector((state)=>state);


    useEffect(()=>{
        dispatch(fetchPostsAsync({activePageNumber:activePageNumber,postType:'health'})).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setHealthData(payload.data.data);
                setTotalRecord(payload.data.total);
                setPerPageRecord(payload.data.per_page);
            }
        })
    },[activePageNumber]);

    useEffect(()=>{
        dispatch(fetchBannerDataAsync('health')).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setBannerData(payload.data);
            }
        })
    },[])
    return (
        <>
            <NavbarTop />
            <OtherPgaeBanner bannerData={bannerData}/>
            <section className='health-sec  position-relative ptb-100'>
                    { userLoading ? <div className='header-loader'> <span className="fa fa-spinner fa-spin m-2"/></div>:''}
                    <Container>
                        <Row className='row-gap-24'>
                            {(healthData.length>0) ? 
                                healthData.map((data, index) => (
                                    <NewsBlogs data={data} key={index} type="health"/>
                            )):<p className="text-center">No Data Found</p>}
                            <Pagination activePageNumber={activePageNumber} setActivePageNumber={setActivePageNumber} totalRecord={totalRecord} perPageRecord={perPageRecord}/>
                        </Row>
                    </Container>
                {/* } */}
            </section>
            <CopyFooter />
        </>
    );
}
export default Health;