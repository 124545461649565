import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchVerifyEmailAsync } from '../redux/auth/authSlice';
import { Container, Row, Col, Image } from "react-bootstrap";
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
const VerifyEmail = () => {
    const dispatch = useDispatch();
    const { id, hash } = useParams();
    useEffect(()=>{
        dispatch(fetchVerifyEmailAsync({userId:id,hash:hash})).then((response)=>{
            let result = response.payload
            if(result.status === true){
                // navigate("/login");
            }
        })
    },[])
    return (
        <>
            <NavbarTop />
            <section className='paymentpages guestuserpage' style={{backgroundImage: `url('/asset/images/paymentbg.png')`, backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} md={8} lg={8}>
                            <div className='paymentpages-inner text-center'>
                                <div className='email-v-inner'>                                    
                                    <Image src='/asset/images/email-v.png' alt="email-v" />
                                    <h1 style={{marginTop:"30px"}}>Your email is successfully verified</h1>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <CopyFooter />
        </>
    );
}

export default VerifyEmail;