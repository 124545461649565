import React, { useEffect, useState } from 'react';
import './index.css';
import { Card, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import {useDispatch} from 'react-redux';
import { wantBreakAsync } from '../../redux/auth/userSlice';

const WantBreak = ({isBreak})=>{
    const dispatch = useDispatch();
    const [isBreakChecked, setIsBreakChecked] = useState(true);
    const ConfirmBox = (e)=>{
        let isChecked = e.target.checked;
        Swal.fire({
            text: `Are you sure want to ${isChecked ? 'take break?':'continue?'}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                setIsBreakChecked(isChecked);
                let status = isChecked ? 1:0;
                dispatch(wantBreakAsync({status:status})).then((result)=>{
                })
            }
        });
    }
    useEffect(()=>{
        setIsBreakChecked(isBreak);
    },[isBreak])
    return (
        <>
            <Card className='wantbreakcard'>
                <h3 className='text-center'>Want a break?</h3>
                <p className='text-center'>How about we temporarily deactivate the account to preserve your health tracking data?</p>
                <div className=''>
                    <Form>
                        <Form.Group className="breakcheck" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Break" name="remember_me_token" onChange={ConfirmBox} checked={isBreakChecked}/>
                        </Form.Group>
                    </Form>
                </div>
            </Card>
        </>
    )
}
export default WantBreak;