import React from 'react';
import './contactinfo.css';
import { Image } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
const ContactInfo = ({support}) => {
    return(
        <>
            <Card className='contactinfo'>
                <h3>Contact Information</h3>
                {/* <Card.Text>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout It is a long established.</Card.Text> */}
                <ListGroup as="ul">
                    <ListGroup.Item as="li">
                        <Link to={"tel:+91-1234567890"}>
                            <div className='contacticon'><Image src='/asset/images/call-lg.svg' alt='call-lg'/></div>
                            <div className='contacticondetails'>
                                <h5>Phone</h5>
                                <p>{support !==undefined ? support.phone:''}</p>
                            </div>
                        </Link>   
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                        <Link to={"mailto:Company@mail.com"}>
                            <div className='contacticon'><Image src='/asset/images/mail-lg.svg' alt='mail-lg'/></div>
                            <div className='contacticondetails'>
                                <h5>Email</h5>
                                <p>{support !==undefined ? support.email:''}</p>
                            </div>
                        </Link>   
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                        <div className='contacticon'><Image src='/asset/images/map-pin-lg.svg' alt='map-pin-lg'/></div>
                        <div className='contacticondetails'>
                            <h5>Location</h5>
                            <p>{support !==undefined ? support.location:''}</p>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Card>
        </>
    )
}
export default ContactInfo;