import React from 'react';
import {
  Form,
  FormLabel,
  FormControl,
  Col,
  Row,
  Button,
} from 'react-bootstrap';

const EditProfileForm = ({action,userLoading,responseData,isDisabled,onSubmit,validationErrorMessage})=>{
    return (
      <>
        <Form className='changepasswordform dashboardform' onSubmit={onSubmit}>
            <Row>
                <Col className='text-start'>
                    <Form.Group className="form-row last-input">
                        <FormLabel>Full Name</FormLabel>
                        <div className='inputbox'>
                            <FormControl type="text"  placeholder="Enter Your Name" name="name" defaultValue={responseData!=='' && responseData !==null ? responseData.data.name:''} disabled={isDisabled}/>   
                        <span className='error'>{validationErrorMessage ? validationErrorMessage.name:''}</span>                         
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className='text-start'>
                    <Form.Group className="form-row last-input">
                        <FormLabel>Phone number</FormLabel>
                        <div className='inputbox'>
                            <FormControl type="number" name="phone" placeholder="Enter Your Phone Number" defaultValue={responseData!=='' &&  responseData !==null ? responseData.data.phone:''} disabled={isDisabled}/>
                            <span className='error'>{validationErrorMessage ? validationErrorMessage.phone:''}</span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className='text-start'>
                    <Form.Group className="form-row last-input">
                        <FormLabel>Email Address</FormLabel>
                        <div className='inputbox'>
                            <FormControl type="email"  placeholder="Enter Your Email" name="email" defaultValue={responseData!=='' && responseData !==null ? responseData.data.email:''}  disabled={true}/>
                            <span className='error'>{validationErrorMessage ? validationErrorMessage.email:''}</span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col className='text-end'>
                <Button className='custom-btn fill-btn shodow-btn' type="submit" disabled={isDisabled}>{userLoading && action ==='updateProfile' ? 'Loading...':'Update Details'}</Button>
            </Col>
            </Row>
        </Form>
      </>
    );
}
export default EditProfileForm;

