import React, { useEffect } from 'react';
import { Link, NavLink, useNavigate, useLocation  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import './index.css';
import { Image } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useDispatch,useSelector } from 'react-redux';
import { logoutUserAsync,fetchUserDetailsAsync, fetchSiteSettingAsync } from '../../redux/auth/authSlice';
import { HelperFunction } from '../../util/Helper';
const NavbarTop = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getlocalStorageData} = HelperFunction();
    const {responseData,siteSetting} = useSelector((state)=>state.auth);
    let location = useLocation();
    const ConfirmBox = ()=>{
        Swal.fire({
        text: "Are you sure want to logout?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(logoutUserAsync()).then((result)=>{
                    if(result.payload.status){
                        navigate('/');
                    }
                })
            }
        });
    }
    useEffect(()=>{
        dispatch(fetchUserDetailsAsync()).then((result)=>{
            if(result.payload.status !== true){
                localStorage.removeItem("user_data");
                const ProtectedRoutes = ['/dashboard','/profilesetting','/webinar','/notifications','/rewards'];
                if(ProtectedRoutes.includes(location.pathname)){
                    navigate('/');
                }
                return false;
            }
        });
        dispatch(fetchSiteSettingAsync());
    },[]);
    const isTokenExist = getlocalStorageData().access_token;
    return (
        <>
            <header>
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand as={Link} to='/home'>
                            <img src={siteSetting.site_logo !=='' && siteSetting.site_logo !==null && siteSetting.site_logo !== undefined ? siteSetting.site_logo: '/asset/images/logo.svg'}  alt="logo" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <NavLink to='/home'>Home</NavLink>
                                <NavLink to='/seminar'>Seminar</NavLink>
                                <NavLink to='/health'>Health</NavLink>
                                <NavLink to='/news'>News</NavLink>
                                <NavLink to='/contactus' >Contact info</NavLink>
                            </Nav>
                        </Navbar.Collapse>
                        {isTokenExist === undefined ? 
                        <div className='navbarlogin'>
                            <Link className='custom-btn fill-btn shodow-btn' to={'/login'}>Login</Link>
                            <Link className='custom-btn light-color-btn' to={'/register'}>Sign Up</Link>
                        </div>:
                        <div className='navprofile'>
                            <Link className='notificationicon' to='/notifications'>
                                <Image src='/asset/images/notification-bing.svg' alt='notification icon' />
                                {responseData!=null && responseData.data !== undefined ? responseData.data.is_notification ? <span className='notificationshow'></span>:'':'' }
                            </Link>
                            <Dropdown className='profiledropdown'>
                                <Dropdown.Toggle>
                                    <div className='navuser-img'>
                                        <Image className='h-100 w-100 object-fit-cover' src={responseData!=null && responseData.data !== undefined ? responseData.data.profile_image ? responseData.data.profile_image:'/asset/images/user.png' : ''}  alt='user profile' />
                                    </div>
                                    <div className='navuserdetails'>
                                        <p>welcome</p>
                                        <span className='navusername'>{responseData!=null && responseData.data !== undefined ? responseData.data.name:'' }</span>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <NavLink className='nav-link' to='/dashboard'>
                                        <span>
                                            <Image src='/asset/images/dashboard-dark.svg' alt='dashboard-dark' />
                                        </span>Dashboard
                                    </NavLink>
                                    <NavLink className='nav-link' to='/profilesetting'>
                                        <span>
                                            <Image src='/asset/images/profile-setting-dark.svg'  alt='profile-setting-dark' />
                                        </span>Profile Settings
                                    </NavLink>
                                    <NavLink className='nav-link' to='/webinar'>
                                        <span>
                                            <Image src='/asset/images/webinar-dark.svg' alt='webinar-dark' />
                                        </span>Webinar
                                        </NavLink>
                                    <Link className='nav-link' onClick={ConfirmBox}>
                                        <span>
                                            <Image src='/asset/images/logout-dark.svg' alt='logout-dark' />
                                        </span>Logout
                                    </Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        }
                    </Container>
                </Navbar>
            </header>
        </>
    );
}
export default NavbarTop;