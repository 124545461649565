import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import NavbarTop from '../components/NavbarTop';
import OtherPgaeBanner from '../components/OtherPagesBanner';
import UpcommingSeminars from '../components/UpcomingSeminars';
import CopyFooter from '../components/CopyFooter';
import Pagination from '../components/Pagination';
import { fetchBannerDataAsync, fetchSeminarAsync,bookSeatAsync } from '../redux/auth/userSlice';
import PageLoader from '../components/PageLoader';
import GuestUserModal from '../components/GuestUserModal';

const Seminar = () => {
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState('');
    const [bannerData, setBannerData] = useState('');
    const [seminarData, setSeminarData] = useState('');
    const [totalRecord,setTotalRecord] = useState('');
    const [seminarId,setSeminarId] = useState('');
    const [perPageRecord,setPerPageRecord] = useState('');
    const [activePageNumber,setActivePageNumber] = useState(1);
    const {bannerloading} = useSelector((state)=>state.user);
    const {responseData} = useSelector((state)=>state.auth);

    useEffect(()=>{
        dispatch(fetchSeminarAsync(activePageNumber)).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setSeminarData(payload.data.data);
                setTotalRecord(payload.data.total);
                setPerPageRecord(payload.data.per_page);
            }
        })
    },[activePageNumber]);

    useEffect(()=>{
        dispatch(fetchBannerDataAsync('seminar')).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setBannerData(payload.data);
            }
        })
    },[])

    const bookSeats = (seminarId) => {
        setSeminarId(seminarId);
        let ObjectData ={}
        if(responseData){
            ObjectData.name = responseData.data.name;
            ObjectData.email = responseData.data.email;
            ObjectData.seminar = seminarId;

            dispatch(bookSeatAsync(ObjectData)).then((result)=>{
                const payload = result.payload;
                if(payload.status){
                    let checkoutUrl = payload.session.url;
                    window.location.href=checkoutUrl;
                }
            })
        }else{
            setModalShow(true);
        }
    }
    return (
        <>
            <NavbarTop />
            <OtherPgaeBanner bannerData={bannerData}/>
            <section className='seminar-sec position-relative ptb-100 position-realtive'>
                {/* { userLoading ? <div className='header-loader'> <span className="fa fa-spinner fa-spin m-2"/></div>:''} */}
                { bannerloading && !modalShow ? <PageLoader/>:''}
                <Container>
                    <Row className="justify-content-between">
                        <Col sm={12} md={12}>
                            {(seminarData.length>0) ? 
                            seminarData.map((data, index) => (
                                <UpcommingSeminars data={data} key={index} bookSeats={bookSeats} modalShow={modalShow} setModalShow={setModalShow}  seminarId={seminarId}/>
                            )):<p className="text-center">No Data Found</p>}
                            <Pagination activePageNumber={activePageNumber} setActivePageNumber={setActivePageNumber} totalRecord={totalRecord} perPageRecord={perPageRecord}/>
                        </Col>
                    </Row>
                    <GuestUserModal show={modalShow} setModalShow={setModalShow} seminarId={seminarId}/>
                </Container>
            </section>
            <CopyFooter />
        </>
    );
}
export default Seminar;