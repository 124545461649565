import React, {useState} from 'react';
import './login.css';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardText,
  Form,
  FormLabel,
  FormControl,
  Col,
  Row,
  InputGroup,
  Button,
  Image
} from 'react-bootstrap';

const RegisterForm = ({onSubmit, isConfirmPasswordMatch,loading,validationErrorMessage})=>{

  const [showPassoword, setshowPassoword] = useState(false);
  const togglePasswordVisibility = () => {
    setshowPassoword(!showPassoword);
  };
  const [confirmPassoword, setconfirmPassoword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setconfirmPassoword(!confirmPassoword);
  };
    return (
      <>
        <div className='login-signup-outer'>
          <div className='titel-holder'>
              <h2>Sign Up Now</h2>
              <p>Please fill in this form to create an account</p>
          </div>
          <Card className="mb-0 cardshadow">
            <CardBody>
                <CardText className="text-center navbarlogin">
                    <Link className='custom-btn light-color' to={'/'}>Login</Link>
                    <Link className='custom-btn fill-btn shodow-btn' to={'/register'}>Sign Up</Link>
                </CardText>
                <Form className='form-input' onSubmit={onSubmit}>
                  <Row>
                      <Col className='text-left'>
                          <Form.Group className="form-row">
                              <div className='position-relative'>
                                <FormLabel>Name</FormLabel>
                                <InputGroup.Text>
                                  <Image src='/asset/images/user.svg' alt='user' />
                                </InputGroup.Text>
                                <FormControl type="text"  placeholder="Enter Your Name" name="name"/>
                              </div>
                              <span className='error'>{validationErrorMessage.name !==undefined ? validationErrorMessage.name:''}</span>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                      <Col className='text-left'>
                          <Form.Group className="form-row">
                              <div className='position-relative'>
                                <FormLabel>Email</FormLabel>
                                <InputGroup.Text>
                                  <Image src='/asset/images/mail.svg' alt='mail' />
                                </InputGroup.Text>
                                <FormControl type="email"  placeholder="Enter Your Email" name="email"/>
                              </div>
                              <span className='error'>{validationErrorMessage.email !==undefined ? validationErrorMessage.email[0]:''}</span>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                      <Col className='text-left'>
                          <Form.Group className="form-row">
                              <div className='position-relative'>
                                <FormLabel>Phone Number</FormLabel>
                                <InputGroup.Text>
                                  <Image src='/asset/images/call-icon.svg' alt='call-icon' />
                                </InputGroup.Text>
                                <FormControl type="number"  placeholder="Enter Your Phone Number" name="phone"/>
                              </div>
                              <span className='error'>{validationErrorMessage.phone !==undefined ? validationErrorMessage.phone:''}</span>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                      <Col className='text-start'>
                          <Form.Group className="form-row last-input">
                              <div className='position-relative'>
                                <FormLabel>Password</FormLabel>
                                <InputGroup.Text>
                                  <Image src='/asset/images/lock.svg' alt='lock' />
                                </InputGroup.Text>
                                <FormControl type={showPassoword ? "text" : "password"}  placeholder="Enter Your Password" name="password"/>
                                <span onClick={togglePasswordVisibility}
                                  className={`form-icon-password toggle-password ${
                                    showPassoword ? "eye-open" : "eye-close"
                                  }`}
                                >
                                  <img
                                    src=
                                    {showPassoword ? "./asset/images/eye-off.svg" : "./asset/images/eye.svg"}
                                    className="img-fluid"
                                    alt="eye-icon"
                                  />
                                </span>
                                </div>
                              <span className='error'>{validationErrorMessage.password !==undefined ? validationErrorMessage.password:''}</span>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                      <Col className='text-start'>
                          <Form.Group className="form-row last-input">
                              <div className='position-relative'>
                                <FormLabel>Confirm Password</FormLabel>
                                <InputGroup.Text>
                                  <Image src='/asset/images/lock.svg' alt='lock' />
                                </InputGroup.Text>
                                <FormControl type={confirmPassoword ? "text" : "password"}  placeholder="Confirm Your Password" name="password_confirmation"/>
                                <span onClick={toggleConfirmPasswordVisibility}
                                  className={`form-icon-password toggle-password ${
                                    confirmPassoword ? "eye-open" : "eye-close"
                                  }`}
                                >
                                  <img
                                    src=
                                    {confirmPassoword ? "./asset/images/eye-off.svg" : "./asset/images/eye.svg"}
                                    className="img-fluid"
                                    alt="eye-icon"
                                  />
                                </span>
                              </div>
                              <span className='error'>{validationErrorMessage.password_confirmation !==undefined ? validationErrorMessage.password_confirmation:''}</span>
                          </Form.Group>
                          <span className="text-danger"><small>{isConfirmPasswordMatch ? 'Confirm password not matched with password':''}</small></span>
                      </Col>
                  </Row>
                  <Row>
                  <Col className='text-left'>
                      <Form.Group className="form-row">
                          <div className='position-relative'>
                            <FormLabel>Golden Gateway Code</FormLabel>
                            <InputGroup.Text>
                              <Image src='/asset/images/Passcode.svg' alt='passcode' />
                            </InputGroup.Text>
                            <FormControl type="text"  placeholder="Enter Your Golden Gateway Code" name="passcode" autoComplete='off'/>
                          </div>
                              <span className='error'>{validationErrorMessage.passcode !==undefined ? validationErrorMessage.passcode:''}</span>
                      </Form.Group>
                  </Col>
                </Row>
                  <Row>
                    <Col>
                      <Button type="submit"  className='custom-btn fill-btn shodow-btn w-100' >{loading ? 'Loading...':'Sign Up'}</Button>
                    </Col>
                  </Row>
                  <Row className='form-row mb-0 mt-4'>
                    <Col className='text-center'>
                        <Form.Text className='already-login'>
                            Already registered <Link to="/">Sign In?</Link>
                        </Form.Text>
                    </Col>
                  </Row>
                </Form>
            </CardBody>
          </Card>
        </div>
      </>
    );
}
export default RegisterForm;