import React from 'react';
import './login.css';
import {
  Card,
  CardBody,
  Form,
  FormLabel,
  FormControl,
  Col,
  Row,
  InputGroup,
  Button,
  Image
} from 'react-bootstrap';

const ForgotPasswordForm = ({onSubmit,validationErrorMessage,loading})=>{
    return (
      <>
        <div className='login-signup-outer'>
          <div className='titel-holder'>
              <h2>Forgot Password</h2>
              <p>Your password will be reset by email.</p>
          </div>
          <Card className="mb-0">
            <CardBody>
                <Form className='form-input' onSubmit={onSubmit}>
                  <Row>
                      <Col className='text-left'>
                          <Form.Group className="form-row">
                              <div className='position-relative'>
                                <FormLabel>Email</FormLabel>
                                <InputGroup.Text>
                                  <Image src='/asset/images/mail.svg' alt='mail' />
                                </InputGroup.Text>
                                <FormControl type="text"  placeholder="Enter Your Email" name='email'/>
                                </div>
                              <span className='error'>{validationErrorMessage.email !==undefined ? validationErrorMessage.email[0]:''}</span>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button className='custom-btn fill-btn shodow-btn w-100' type="submit">{loading ? "Loading...":"Submit"}</Button>
                    </Col>
                  </Row>
                </Form>
            </CardBody>
          </Card>
        </div>
      </>
    );
}
export default ForgotPasswordForm;