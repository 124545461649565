import React,{useEffect, useState} from 'react';
import NavbarTop from '../components/NavbarTop';
import CopyFooter from '../components/CopyFooter';
import SideBar from '../components/Sidebar';
import MyTask from '../components/MyTask';
import TaskModal from '../components/TaskModal';
import QuoteDay from '../components/QuoteDay';
import { Container, Row, Col } from "react-bootstrap";
import DailyTask from '../components/DailyTask';
import WeeklyTask from '../components/WeeklyTask';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuoteOfTheDayDataAsync, completeTaskAsync } from '../redux/auth/userSlice';
import PageLoader from '../components/PageLoader';

const Dashboard = () => {
    const [toggleClass, setToggleClass] = useState(false);
    const [quotesMessage, setQuotesMessage] = useState('');
    const [quotesPercentage, setQuotesPercentage] = useState(0);
    const [quotesId, setQuotesId] = useState(0);
    const [isInBreak, setIsInBreak] = useState();
    const [isTodayTaskCompleted, setIsTodayTaskCompleted] = useState(false);
    const [isVisibleTask, setIsVisibleTask] = useState(true);
    const [taskTrackingData, setTaskTrackingData] = useState('');

    const dispatch = useDispatch();
    const {userLoading} = useSelector((state)=>state.user);

    useEffect(()=>{
        fetchDashboardData();
    },[])

    const fetchDashboardData = ()=> {
        dispatch(fetchQuoteOfTheDayDataAsync()).then((result)=>{
            const payload = result.payload;
            if(payload.status === true){
                let data = payload.data;
                if(data !== null){
                    setQuotesId(data.today_qoute_data.id);
                    setQuotesMessage(data.today_qoute_data.message);
                    setIsInBreak(data.is_break);
                    setQuotesPercentage(data.today_qoute_data.percentage);
                    setIsTodayTaskCompleted(data.today_qoute_data.is_completed);
                    setTaskTrackingData(data.task_tracking_data);
                }
            }
        })
    }
    const completeTask = () => {
        dispatch(completeTaskAsync(quotesId)).then((result) => {
              let respone = result.payload;
              if(respone.status===true){
                fetchDashboardData();
              }
        });
    }
    return (
        <>
            {userLoading ? <PageLoader/> :<>
            <NavbarTop />
            <div className={toggleClass ? "maincontentouter opensidebar":"maincontentouter"}>
                <div className='leftsidebar'>
                    <SideBar setToggleClass={setToggleClass} toggleClass={toggleClass}/>
                </div>
                <div className='maincontent'>
                    <div className='dashboardtext'>
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    {quotesMessage !==null && !isTodayTaskCompleted && !isInBreak && 
                                        <MyTask completeTask={completeTask} isVisibleTask={isVisibleTask} setIsVisibleTask={setIsVisibleTask}/>
                                    }    
                                    <div className='task-trakingouter'>
                                        <div className='task-trakingcard'>
                                            <h2 className="after-line">task tracking</h2>
                                            <Row className='row-gap-24'>
                                                <Col xxl={8} xl={7} lg={6} md={12}>
                                                    <DailyTask taskTrackingData={taskTrackingData}/>
                                                </Col>
                                                <Col xxl={4} xl={5} lg={6} md={12}>
                                                    <WeeklyTask taskTrackingData={taskTrackingData}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <QuoteDay quotesMessage={quotesMessage} quotesPercentage={quotesPercentage}/>
                                    {quotesMessage !==null && !isTodayTaskCompleted && !isInBreak  && 
                                        <TaskModal completeTask={completeTask} isVisibleTask={isVisibleTask} setIsVisibleTask={setIsVisibleTask}/>
                                    }         
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <CopyFooter />
                </div>
            </div> 
            </>}
        </>
    );
}
export default Dashboard;