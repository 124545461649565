import React from 'react'
import { Container, Row, Col, Image } from "react-bootstrap";
import NavbarTop from "../components/NavbarTop";
import CopyFooter from "../components/CopyFooter";
import GuestUserForm from '../form/GuestUserForm';
import {bookSeatAsync } from '../redux/auth/userSlice';

const GuestUser = () => {
    // const bookSeats = (seminarId) => {
    //     let ObjectData ={}
    //     if(responseData){
    //         ObjectData.name = responseData.data.name;
    //         ObjectData.email = responseData.data.email;
    //         ObjectData.seminar = seminarId;
    //     }else{
    //         navigate('/guestuser');
    //         return false;
    //     }
    //     dispatch(bookSeatAsync(ObjectData)).then((result)=>{
    //         const payload = result.payload;
    //         if(payload.status){
    //             let checkoutUrl = payload.session.url;
    //             window.location.href=checkoutUrl;
    //         }
    //     })
    // }
  return (
    <>
        <NavbarTop />
        <section className='paymentpages guestuserpage' style={{backgroundImage: `url('/asset/images/paymentbg.png')`, backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat" }}>
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={8}>
                        <div className='paymentpages-inner text-center'>
                            <h1>Please enter the detail for complete your payment</h1>
                            <p>Lorem Empowering individuals to fulfill their full potential and live their dreams.</p>

                            <GuestUserForm />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <CopyFooter />
    </>
  )
}

export default GuestUser;
