import React,{useEffect, useState} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import NavbarTop from '../components/NavbarTop';
import OtherPgaeBanner from '../components/OtherPagesBanner';
import ContactForm from '../form/ContactForm';
import ContactInfo from '../components/ContactInfo';
import CopyFooter from '../components/CopyFooter';
import { useDispatch, useSelector } from 'react-redux';
import { contactUsAsync, fetchBannerDataAsync,ResetUserStateAsync } from '../redux/auth/userSlice';
import { validationErrors } from '../request/errorHandler';
import PageLoader from '../components/PageLoader';

const Contact = () => {
    const {user:{userLoading,bannerloading, userError}, auth:{siteSetting:{support}}} = useSelector((state)=>state);
    const validationErrorMessage = validationErrors(userError);
    const[bannerData, setBannerData] = useState('');
    const dispatch = useDispatch();
    const onSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target); 
        dispatch(contactUsAsync(formData)).then((result)=>{
        let response = result.payload;
        if(response.status){
            e.target.reset();
            dispatch(ResetUserStateAsync());
        }
        // if(response.message ==="Request failed with status code 401"){
        // }else{
        // }
        });
    };
    useEffect(()=>{
        dispatch(fetchBannerDataAsync('contact-us')).then((result)=>{
            const payload = result.payload;
            if(payload.status){
                setBannerData(payload.data);
            }
        })
    },[]);
    return (
        <>
            {bannerloading ? <PageLoader/> :''}
            <NavbarTop />
            <OtherPgaeBanner bannerData={bannerData}/>
            <section className='getintuch-sec  ptb-100'>
                <Container>
                    <Row>                    
                        <Col sm={12} md={6}>
                            <div className='contactform'>
                                <h2>Get In Touch</h2>
                                <ContactForm onSubmit={onSubmit} validationErrorMessage={validationErrorMessage} userLoading={userLoading} />
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <ContactInfo support={support}/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <CopyFooter />
        </>
    );
}
export default Contact;