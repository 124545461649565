import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Image, ListGroup, Button } from "react-bootstrap";
import NavbarTop from "../components/NavbarTop";
import CopyFooter from "../components/CopyFooter";
import {useParams  } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {fetchSeminarTicketDetailsAsync} from '../redux/auth/userSlice';
import PageExpired from '../components/PageExpired';
import PageLoader from '../components/PageLoader';
const SeminarTicket = () => {
    const { email, token } = useParams();
    const [eventData, setEventData] = useState('');
    const dispatch = useDispatch();
    useEffect(()=>{
        let Object = {
            email:email,
            token:token,
        }
        dispatch(fetchSeminarTicketDetailsAsync(Object)).then((result)=>{
            let response = result.payload;
            if(response.status === true){
                setEventData(response.data);
            }else{
                setEventData(false);
            }
        });
    },[])
    const Print = () =>{  
        var printButtons = document.getElementById("shodow-btn");
        printButtons.style.visibility = 'hidden';
        var printContents = document.getElementById("print-area").innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        printButtons.style.visibility = 'visible';
        document.body.innerHTML = originalContents;
      }
      
  return (
    <>
        <NavbarTop />
        {eventData ? 
            <section className='paymentpages seminarticket-sec' style={{backgroundImage: `url('/asset/images/paymentbg.png')`, backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} md={8} lg={8}>
                            <div className='paymentpages-inner' id='printablediv' >
                                <div className='text-center'>
                                    <Image src='/asset/images/paymentsuccess.png' alt="paymetsuccess" />
                                    <h1 style={{ margin:'30px 0 12px'}}>Payment Successful</h1>
                                    <p>Please check the seminar payment detail below.</p>
                                </div>
                                <div id="print-area" className='seminarticketdetail' style={{backgroundColor: '#f5f7f9', marginTop:'27px'}}>
                                    <div className='seminarticket d-flex align-items-center'>
                                        <div className='seminarticketimg overflow-hidden'>
                                            <Image className='h-100 w-100 object-fit-cover' src={eventData ? eventData.seminar_image :''}alt='seminarticketimg' />
                                        </div>
                                        <div className='seminartickettext'>
                                            <h4>{ eventData ? eventData.title : ''}</h4>
                                            <ListGroup as="ul"> 
                                                <ListGroup.Item as="li">                                  
                                                    <Image src='/asset/images/calendar-dark.svg' alt='calendar-dark'/>  { eventData ? eventData.date : ''}
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </div>
                                    </div>
                                    <div className='seminarticketinfo'>
                                        <ListGroup as="ul">
                                            <ListGroup.Item as="li">
                                                <span className='seminarticketinfohead'>Name</span>          
                                                <span className='seminarticketinfobody'>{ eventData ? eventData.name : ''}</span>
                                            </ListGroup.Item>

                                            <ListGroup.Item as="li">
                                                <span className='seminarticketinfohead'>Booking Number</span>          
                                                <span className='seminarticketinfobody'>{ eventData ? eventData.booking_number : ''}</span>
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <span className='seminarticketinfohead'>Seminar Time</span>          
                                                <span className='seminarticketinfobody'>{ eventData ? eventData.start_time+' To '+ eventData.end_time: ''}</span>
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <span className='seminarticketinfohead'>Seminar Venue</span>          
                                                <span className='seminarticketinfobody'>{ eventData ? eventData.venue : ''} </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" className='seminarticketprice'>
                                                <span className='seminarticketinfohead'>Total Price</span>          
                                                <span className='seminarticketinfobody text-start'>${ eventData ? eventData.ticket_price : ''}</span>
                                            </ListGroup.Item>
                                        </ListGroup>
                                        <Button id="shodow-btn" className='custom-btn fill-btn shodow-btn hide-section mt-4 d-none' onClick={Print}>Print Ticket</Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> :
         eventData === false ? <PageExpired/>:<PageLoader/>
        }
        <CopyFooter />
    </>
  )
}

export default SeminarTicket;
