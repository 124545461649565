import React, {useState} from 'react';
import {
  Form,
  FormLabel,
  FormControl,
  Col,
  Row,
  Button,
  Image
} from 'react-bootstrap';

const ChangePassword = ({action,userLoading, handleChangePassword, validationErrorMessage})=>{

const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassoword, setShowNewPassoword] = useState(false);
const [confirmPassoword, setconfirmPassoword] = useState(false);

    return (
      <>
        <Form className='changepasswordform dashboardform' onSubmit={handleChangePassword}>
            <Row>
                <Col className='text-start'>
                    <Form.Group className="form-row last-input">
                        <FormLabel>Old Password</FormLabel>
                        <div className='inputbox'>
                            <FormControl type={showOldPassword ? "text" : "password"}  placeholder="Enter Old Password" name="old_password"/>
                            <span onClick={()=>{setShowOldPassword(!showOldPassword)}}
                            className={`form-icon-password toggle-password ${
                                showOldPassword ? "eye-open" : "eye-close"
                            }`}
                            >
                            <Image
                                src=
                                {showOldPassword ? "/asset/images/eye-off.svg" : "/asset/images/eye.svg"}
                                className="img-fluid"
                                alt="eye-icon"
                            />
                            </span>
                            <span className='error'>{validationErrorMessage ? validationErrorMessage.old_password ? validationErrorMessage.old_password[0]:'':''}</span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className='text-start'>
                    <Form.Group className="form-row last-input">
                        <FormLabel>New Password</FormLabel>
                        <div className='inputbox'>
                            <FormControl type={showNewPassoword ? "text" : "password"}  placeholder="Enter New Password" name="new_password"/>
                            <span onClick={()=>{setShowNewPassoword(!showNewPassoword)}}
                            className={`form-icon-password toggle-password ${
                                showNewPassoword ? "eye-open" : "eye-close"
                            }`}
                            >
                            <Image
                                src=
                                {showNewPassoword ? "/asset/images/eye-off.svg" : "/asset/images/eye.svg"}
                                className="img-fluid"
                                alt="eye-icon"
                            />
                            </span>
                            <span className='error'>{validationErrorMessage ? validationErrorMessage.new_password ? validationErrorMessage.new_password[0]:'':''}</span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className='text-start'>
                    <Form.Group className="form-row last-input">
                        <FormLabel>Confirm Password</FormLabel>
                        <div className='inputbox'>
                            <FormControl type={confirmPassoword ? "text" : "password"}  placeholder="Confirm Your Password" name="confirm_password"/>
                            <span onClick={()=>{setconfirmPassoword(!confirmPassoword)}}
                            className={`form-icon-password toggle-password ${
                                confirmPassoword ? "eye-open" : "eye-close"
                            }`}
                            >
                            <Image
                                src=
                                {confirmPassoword ? "/asset/images/eye-off.svg" : "/asset/images/eye.svg"}
                                className="img-fluid"
                                alt="eye-icon"
                            />
                            </span>
                            <span className='error'>{validationErrorMessage ? validationErrorMessage.confirm_password ? validationErrorMessage.confirm_password[0]:'':''}</span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col className='text-end'>
                <Button className='custom-btn fill-btn shodow-btn' type="submit">{userLoading && action ==='changePassword' ? 'Loading...':'Change Passsword'} </Button>
            </Col>
            </Row>
        </Form>
      </>
    );
}
export default ChangePassword;
