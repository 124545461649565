import axios from 'axios';
import successHandler from '../successHandler';
import {validationErrors as errorHandler} from '../errorHandler';
import { HelperFunction } from '../../util/Helper';
const API_BASE_URL = process.env.REACT_APP_API_URL;
const {getlocalStorageData} = HelperFunction();
export const login = async ({ loginData }) => {
    try {
      const response = await axios.post(
        API_BASE_URL + `login?timestamp=${new Date().getTime()}`,
        loginData
      );
      const { data } = response;
      await successHandler(response);
      return data;
    } catch (error) {
      await errorHandler(error.response);
      return error;
    }
  };

  export const register = async ({ registerData }) => {
    try {
      const response = await axios.post(API_BASE_URL + `register`, registerData);
  
      const {  data } = response;
      await successHandler(response);
      return data;
    } catch (error) {
      await errorHandler(error.response);
      return error;
    }
  };

  export const forgotPassword = async ({ forgotData }) => {
    try {
      const response = await axios.post(API_BASE_URL + `forgot-password`, forgotData);
      const { data } = response;
      await successHandler(response);
      return data;
    } catch (error) {
      await errorHandler(error.response);
      return error;
    }
  };

  export const resetUserPassword = async ({ resetData }) => {
    try {
      const response = await axios.post(API_BASE_URL + `reset-password`, resetData);
      const { data } = response;
      await successHandler(response);
      return data;
    } catch (error) {
      await errorHandler(error.response);
      return error;
    }
  };

  export const verifyEmail = async (verifyData) => {
    try {
      const {userId, hash} = verifyData;
      const response = await axios.get(API_BASE_URL + `email/verify/${userId}/${hash}`);
      const { data } = response;
      await successHandler(response);
      return data;
    } catch (error) {
      await errorHandler(error.response);
      return error;
    }
  };

  export const getUserDetails = async () => {
    try {
      const token = getlocalStorageData().access_token;
      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      };

      const response = await axios.get(API_BASE_URL + `user-details`, { headers: headers });
      const { data } = response;
      await successHandler(response);
      return data;
    } catch (error) {
      await errorHandler(error.response);
      return error;
    }
  };

  export const getSiteSetting = async () => {
    try {
      const response = await axios.get(API_BASE_URL + `get-site-settings-details`);
      const { data } = response;
      await successHandler(response);
      return data;
    } catch (error) {
      await errorHandler(error.response);
      return error;
    }
  };

  export const logoutUser = async () => {
    try {
      const token = getlocalStorageData().access_token;
      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      };
      const response = await axios.post(API_BASE_URL + `logout`, {},{ headers: headers });
      const {  data } = response;
      if(data.status){
        localStorage.removeItem("user_data");
      }
      return data;
    } catch (error) {
      await errorHandler(error.response);
      return error;
    }
  };