import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navigate, useLocation } from 'react-router-dom';
import { HelperFunction } from '../util/Helper';

const Protected = ({ children }) => {
  const {getlocalStorageData} = HelperFunction();
  const isTokenExist = getlocalStorageData().access_token;
  let location = useLocation();
  if (isTokenExist === undefined) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <Outlet />
  );
};

export default Protected;