import React,{useEffect, useState} from 'react';
import './index.css';
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { Link } from 'react-router-dom';
import {useSelector } from 'react-redux';

const CopyFooter = ()=>{
    const {siteSetting:{social_media}} = useSelector((state)=>state.auth);
    const[socialMedia, setSocialMedia] = useState('');
    useEffect(()=>{
        setSocialMedia(social_media);
    },[social_media]);
    return (
        <>
            <footer className='copyfooter' >
                <Container>
                    <Row className="justify-content-between">
                        <Col sm={12} md={12} lg={12} className='align-self-center'>
                            <div className='copyfooter-text'>
                                <p>Copyright © {new Date().getFullYear()} <Link>Trueyou.Club</Link> All Rights Reserved</p>
                                <ListGroup horizontal  as="ul">
                                    {/* <ListGroup.Item as="li">
                                        <Link to={socialMedia !== undefined ? socialMedia.youtube:'' } className='yt' target='_blank'>
                                            <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.7281 2.56533C13.6579 2.25835 13.515 1.97708 13.3137 1.74994C13.1124 1.52279 12.86 1.35782 12.5818 1.27167C11.5655 1 7.50024 1 7.50024 1C7.50024 1 3.435 1 2.41869 1.29754C2.14053 1.38369 1.88807 1.54867 1.6868 1.77581C1.48553 2.00296 1.34258 2.28422 1.27239 2.5912C1.08639 3.72028 0.995403 4.86566 1.00058 6.01294C0.993952 7.16885 1.08494 8.32296 1.27239 9.46054C1.34977 9.75799 1.49592 10.0286 1.69672 10.2461C1.89752 10.4637 2.14619 10.6208 2.41869 10.7025C3.435 11 7.50024 11 7.50024 11C7.50024 11 11.5655 11 12.5818 10.7025C12.86 10.6163 13.1124 10.4513 13.3137 10.2242C13.515 9.99704 13.6579 9.71578 13.7281 9.4088C13.9127 8.28822 14.0036 7.15157 13.9999 6.01294C14.0065 4.85702 13.9155 3.70291 13.7281 2.56533Z" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6.17065 8.1281L9.56821 6.01296L6.17065 3.89783V8.1281Z" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </Link>
                                    </ListGroup.Item> */}
                                    <ListGroup.Item as="li">
                                        <Link to={socialMedia !== undefined ? socialMedia.instagram:'' } className='insta' target='_blank'>
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.75 1H4.25C2.45507 1 1 2.45507 1 4.25V10.75C1 12.5449 2.45507 14 4.25 14H10.75C12.5449 14 14 12.5449 14 10.75V4.25C14 2.45507 12.5449 1 10.75 1Z" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M10.0996 7.09033C10.1798 7.63129 10.0874 8.18377 9.83556 8.66919C9.58368 9.15461 9.18516 9.54825 8.69667 9.79412C8.20818 10.04 7.6546 10.1256 7.11467 10.0387C6.57475 9.9518 6.07596 9.69688 5.68926 9.31018C5.30256 8.92348 5.04764 8.4247 4.96076 7.88477C4.87388 7.34484 4.95946 6.79126 5.20533 6.30277C5.4512 5.81428 5.84483 5.41576 6.33025 5.16389C6.81567 4.91201 7.36815 4.81961 7.90911 4.89983C8.46091 4.98165 8.97177 5.23878 9.36621 5.63323C9.76066 6.02768 10.0178 6.53853 10.0996 7.09033Z" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11.0759 3.92517H11.0859" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <Link to={socialMedia !== undefined ? socialMedia.linkedin:'' } className='linkedIn' target='_blank'>
                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clippath="url(#clip0_1079_670)">
                                            <path d="M3.3913 12.8675H0V4.10669H3.3913V12.8675ZM0.565183 12.3023H2.82612V4.67187H0.565183V12.3023Z" fill="#0A2540"/>
                                            <path d="M12.9997 12.8675H9.60844V8.06318C9.60844 7.59497 9.22882 7.21535 8.76061 7.21535C8.2923 7.21535 7.91279 7.59497 7.91279 8.06318V12.8675H4.52148V4.10669H7.91279V4.67539C8.42467 4.3053 9.04036 4.10628 9.67199 4.10669C11.5054 4.10669 12.9997 5.5939 12.9997 7.42379V12.8675ZM10.1736 12.3023H12.4345V7.42379C12.4306 5.90139 11.1944 4.66991 9.67199 4.67187C8.97609 4.67187 8.32252 4.96507 7.83506 5.49496L7.3475 6.02837V4.67187H5.08667V12.3023H7.3475V8.06318C7.3475 7.28273 7.98016 6.65017 8.76061 6.65017C9.54096 6.65017 10.1736 7.28273 10.1736 8.06318V12.3023Z" fill="#0A2540"/>
                                            <path d="M1.69565 3.54145C0.759131 3.54145 0 2.78232 0 1.8458C0 0.909381 0.759131 0.150146 1.69565 0.150146C2.63217 0.150146 3.3913 0.909381 3.3913 1.8458C3.38934 2.78149 2.63135 3.53959 1.69565 3.54145ZM1.69565 0.715433C1.07137 0.715433 0.565183 1.22152 0.565183 1.8458C0.565183 2.47018 1.07137 2.97627 1.69565 2.97627C2.31993 2.97627 2.82612 2.47018 2.82612 1.8458C2.82612 1.22152 2.31993 0.715433 1.69565 0.715433Z" fill="#0A2540"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_1079_670">
                                            <rect width="13" height="13" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                        </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <Link to={socialMedia !== undefined ? socialMedia.facebook:'' } className='fb' target='_blank'>
                                            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.3125 1H6.31818C5.43664 1 4.5912 1.34241 3.96786 1.9519C3.34451 2.5614 2.99432 3.38805 2.99432 4.25V6.2H1V8.8H2.99432V14H5.65341V8.8H7.64773L8.3125 6.2H5.65341V4.25C5.65341 4.07761 5.72345 3.91228 5.84812 3.79038C5.97279 3.66848 6.14187 3.6 6.31818 3.6H8.3125V1Z" stroke="#0A2540" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </Link>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}
export default CopyFooter;