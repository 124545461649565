import { Routes, Route } from 'react-router-dom';
import Seminar from '../pages/Seminar';
import News from '../pages/News';
import Contact from '../pages/Contact';
import Health from '../pages/Health';
import NewsDetail from '../pages/NewsDetail';
import Home from '../pages/Home';
import Dashboard from '../pages/Dashbard';
import ProfileSetting from '../pages/ProfileSetting';
import Webinar from '../pages/Webinar';
import Notifications from '../pages/Notifications';
import Rewards from '../pages/Rewards';
import Protected from '../util/Protected';
import AuthRouter from '../util/AuthRouter';
import PageNotFound from '../pages/PageNotFound';

import Register from '../pages/Register';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ResetNewPassword from '../pages/ResetNewPassword';
import VerifyEmail from '../pages/VerifyEmail';
import PaymentSuccess from '../pages/PaymentSuccess';
import PaymentFailed from '../pages/PaymentFailed';
import GuestUser from '../pages/GuestUser';
import SeminarTicket from '../pages/SeminarTicket';
import HealthDetail from '../pages/HealthDetail';

export default function PageRouter() {
    return (
     <>
       {/* <NavbarTop/> */}

      <Routes>
        <Route element={<Home />} path="/home" />
        <Route element={<Home />} path="/" />
        <Route element={<Seminar />} path="/seminar" />
        <Route element={<News />} path="/news" />
        <Route element={<Health />} path="/health" />
        <Route element={<Contact />} path="/contactus" />
        <Route element={<NewsDetail />} path="/news/details/:slug" />
        <Route element={<HealthDetail/>} path="/health/details/:slug" />
        <Route element={<PaymentSuccess />} path="/paymentsuccess" />
        <Route element={<PaymentFailed />} path="/paymentfailed" />
        <Route element={<GuestUser />} path="/guestuser" />
        <Route element={<SeminarTicket />} path="/seminar-ticket/:email/:token" />

        <Route element={<AuthRouter />}>
          <Route element={<Register />} path="/register" />
          <Route element={<Login />} path="/login" />
          <Route element={<ForgotPassword />} path="/forgotpassword" />
          <Route element={<ResetNewPassword />} path="/reset-password/:token" />
          <Route element={<VerifyEmail />} path="/email/verify/:id/:hash" />
        </Route>

        <Route element={<Protected />}>
          <Route element={<Dashboard />} path="/dashboard" />
          <Route element={<ProfileSetting />} path="/profilesetting" />
          <Route element={<Webinar />} path="/webinar" />
          <Route element={<Notifications />} path="/notifications" />
          <Route element={<Rewards />} path="/rewards" />
        </Route>

        <Route path="*" element={<PageNotFound />}/>
      </Routes>
     </>
    );
  }