import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import PageRouter from './router/PageRouter';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from 'react-redux';
import store from '../src/redux/store';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Provider store={store}>
          {/* <AuthRouter /> */}
          <PageRouter />
      </Provider>
    </BrowserRouter>

  );
}
export default App;
