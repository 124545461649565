import { toast } from "react-toastify";
const successHandler = (response) => {
    const { data } = response;
    if (data && data.status === true) {
        toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }else{
    }
}
export default successHandler;
