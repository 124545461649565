// authReducer.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as services from '../../request/user'

// Define the initial state
const initialState = {
  userLoading: false,
  userError: null,
  action:'',
  bannerloading:false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // seminar page
    .addCase(fetchSeminarAsync.pending, (state) => {
      state.action = 'fetchSeminar';
      state.userLoading = true;
    })
    .addCase(fetchSeminarAsync.fulfilled, (state, action) => {
      state.action = 'fetchSeminar';
      state.userLoading = false;
    })
    .addCase(fetchSeminarAsync.rejected, (state, action) => {
      state.action = 'fetchSeminar';
      state.user = action.payload;
    })

    // news page
    .addCase(fetchPostsAsync.pending, (state) => {
      state.action = 'fetchNews';
      state.userLoading = true;
    })
    .addCase(fetchPostsAsync.fulfilled, (state, action) => {
      state.action = 'fetchNews';
      state.userLoading = false;
    })
    .addCase(fetchPostsAsync.rejected, (state, action) => {
      state.action = 'fetchNews';
      state.user = action.payload;
    })
    
    // webinar page
    .addCase(fetchWebinarAsync.pending, (state) => {
      state.action = 'fetchWebinar';
      state.userLoading = true;
    })
    .addCase(fetchWebinarAsync.fulfilled, (state, action) => {
      state.action = 'fetchWebinar';
      state.userLoading = false;
    })
    .addCase(fetchWebinarAsync.rejected, (state, action) => {
      state.action = 'fetchWebinar';
      state.user = action.payload;
    })

    // banner page
    .addCase(fetchBannerDataAsync.pending, (state) => {
      state.action = 'fetchBanner';
      state.bannerloading = true;
    })
    .addCase(fetchBannerDataAsync.fulfilled, (state, action) => {
      state.action = 'fetchBanner';
      state.bannerloading = false;
    })
    .addCase(fetchBannerDataAsync.rejected, (state, action) => {
      state.action = 'fetchBanner';
      state.bannerloading = false;
      state.user = action.payload;
    })

    // quotes of the day page
    .addCase(fetchQuoteOfTheDayDataAsync.pending, (state) => {
      state.action = 'fetchQuote';
      state.userLoading = true;
    })
    .addCase(fetchQuoteOfTheDayDataAsync.fulfilled, (state, action) => {
      state.action = 'fetchQuote';
      state.userLoading = false;
    })
    .addCase(fetchQuoteOfTheDayDataAsync.rejected, (state, action) => {
      state.action = 'fetchQuote';
      state.userLoading = false;
      // state.user = action.payload;
    })    

    // Reward page
    .addCase(fetchRewardDataAsync.pending, (state) => {
      state.action = 'fetchReward';
      // state.bannerloading = true;
    })
    .addCase(fetchRewardDataAsync.fulfilled, (state, action) => {
      state.action = 'fetchReward';
      // state.bannerloading = false;
    })
    .addCase(fetchRewardDataAsync.rejected, (state, action) => {
      state.action = 'fetchReward';
      // state.bannerloading = false;
      // state.user = action.payload;
    }) 

    // Reward page
    .addCase(fetchNotificationDataAsync.pending, (state) => {
      state.action = 'fetchNotification';
      state.userLoading = true;
    })
    .addCase(fetchNotificationDataAsync.fulfilled, (state, action) => {
      state.action = 'fetchNotification';
      state.userLoading = false;
    })
    .addCase(fetchNotificationDataAsync.rejected, (state, action) => {
      state.action = 'fetchNotification';
      state.userLoading = false;
      // state.user = action.payload;
    }) 

    // fetch latest seminar blog for login & home page
    .addCase(fetchLatestSeminarBlogAsync.pending, (state) => {
      state.action = 'fetchLatestSeminarBlog';
      state.bannerloading = true;
    })
    .addCase(fetchLatestSeminarBlogAsync.fulfilled, (state, action) => {
      state.action = 'fetchLatestSeminarBlog';
      state.bannerloading = false;
    })
    .addCase(fetchLatestSeminarBlogAsync.rejected, (state, action) => {
      state.action = 'fetchLatestSeminarBlog';
      state.bannerloading = false;
      state.user = action.payload;
    })

    // fetch single news details
    .addCase(fetchSingleNewsDetailsAsync.pending, (state) => {
      state.action = 'fetchSingleNewsDetails';
      state.userLoading = true;
    })
    .addCase(fetchSingleNewsDetailsAsync.fulfilled, (state, action) => {
      state.action = 'fetchSingleNewsDetails';
      state.userLoading = false;
    })
    .addCase(fetchSingleNewsDetailsAsync.rejected, (state, action) => {
      state.action = 'fetchSingleNewsDetails';
      state.userLoading = false;
      state.user = action.payload;
    })

    // fetch seminar ticket details
    .addCase(fetchSeminarTicketDetailsAsync.pending, (state) => {
      state.action = 'fetchSeminarTicketDetails';
      state.userLoading = true;
    })
    .addCase(fetchSeminarTicketDetailsAsync.fulfilled, (state, action) => {
      state.action = 'fetchSeminarTicketDetails';
      state.userLoading = false;
    })
    .addCase(fetchSeminarTicketDetailsAsync.rejected, (state, action) => {
      state.action = 'fetchSeminarTicketDetails';
      state.userLoading = false;
      state.user = action.payload;
    })
    
    // update user profile
    .addCase(updateUserProfileAsync.pending, (state) => {
      state.action = 'updateProfile';
      state.userLoading = true;
    })
    .addCase(updateUserProfileAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.action = 'updateProfile';
      state.userLoading = false;
      if(result.message ==="Request failed with status code 422"){
        state.userError = result.response.data;
      }else{
        state.userError = null;
      }
    })
    .addCase(updateUserProfileAsync.rejected, (state, action) => {
      state.action = 'updateProfile';
      state.user = action.payload;
    })

    // update user profile pic
    .addCase(updateUserProfilePicAsync.pending, (state) => {
      state.action = 'updateProfilePic';
      state.userLoading = true;
    })
    .addCase(updateUserProfilePicAsync.fulfilled, (state, action) => {
      state.action = 'updateProfilePic';
      state.userLoading = false;
      let result = action.payload;
      if(result.message ==="Request failed with status code 422"){
        state.userError = result.response.data;
      }else{
        state.userError = null;
      }
    })
    .addCase(updateUserProfilePicAsync.rejected, (state, action) => {
      state.action = 'updateProfilePic';
      state.user = action.payload;
    })

    // update user change password
    .addCase(changePasswordAsync.pending, (state) => {
      state.action = 'changePassword';
      state.userLoading = true;
    })
    .addCase(changePasswordAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.action = 'changePassword';
      state.userLoading = false;
      if(result.message ==="Request failed with status code 422"){
        state.userError = result.response.data;
      }else{
        state.userError = null;
      }
    })
    .addCase(changePasswordAsync.rejected, (state, action) => {
      state.action = 'changePassword';
      state.user = action.payload;
    })

    // want break user profile
    .addCase(wantBreakAsync.pending, (state) => {
      state.action = 'wantBreak';
      state.userLoading = true;
    })
    .addCase(wantBreakAsync.fulfilled, (state, action) => {
      state.action = 'wantBreak';
      state.userLoading = false;
    })
    .addCase(wantBreakAsync.rejected, (state, action) => {
      state.action = 'wantBreak';
      state.user = action.payload;
    })

    // contactus page
    .addCase(contactUsAsync.pending, (state) => {
      state.action = 'contactus';
      state.userLoading = true;
    })
    .addCase(contactUsAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.action = 'contactus';
      state.userLoading = false;
      if(result.message ==="Request failed with status code 422"){
        state.userError = result.response.data;
      }else{
        state.isAuthenticated = true;
        state.responseData = result;
      }
    })
    .addCase(contactUsAsync.rejected, (state, action) => {
      state.action = 'contactus';
      state.userLoading = false;
      state.user = action.payload;
    })

    // complete page
    .addCase(completeTaskAsync.pending, (state) => {
      state.action = 'completeTask';
      // state.userLoading = true;
    })
    .addCase(completeTaskAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.action = 'completeTask';
      // state.userLoading = false;
      if(result.message ==="Request failed with status code 422"){
        state.userError = result.response.data;
      }else{
        state.isAuthenticated = true;
        state.responseData = result;
      }
    })
    .addCase(completeTaskAsync.rejected, (state, action) => {
      state.action = 'completeTask';
      state.userLoading = false;
      state.user = action.payload;
    })
    
    // Notification page
    .addCase(deleteNotificationAsync.pending, (state) => {
      state.action = 'removeNotification';
      // state.userLoading = true;
    })
    .addCase(deleteNotificationAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.action = 'removeNotification';
      // state.userLoading = false;
      if(result.message ==="Request failed with status code 422"){
        state.userError = result.response.data;
      }else{
        state.isAuthenticated = true;
        state.responseData = result;
      }
    })
    .addCase(deleteNotificationAsync.rejected, (state, action) => {
      state.action = 'removeNotification';
      // state.userLoading = false;
      state.user = action.payload;
    })
    
    //book seat
    .addCase(bookSeatAsync.pending, (state) => {
      state.action = 'bookSeat';
      state.bannerloading = true;
    })
    .addCase(bookSeatAsync.fulfilled, (state, action) => {
      let result = action.payload;
      state.action = 'bookSeat';
      state.bannerloading = false;
      if(result.message ==="Request failed with status code 422"){
        state.userError = result.response.data;
      }else{
        state.isAuthenticated = true;
        state.responseData = result;
      }
    })
    .addCase(bookSeatAsync.rejected, (state, action) => {
      state.action = 'bookSeat';
      state.bannerloading = false;
      // state.user = action.payload;
    })

    
    // reset state
    .addCase(ResetUserStateAsync.pending, (state, action) => {
      state = initialState;
      return state;
    })
    .addCase(ResetUserStateAsync.fulfilled, (state, action) => {
      state = initialState;
      return state;
    })

  }
});


// createAsyncThunk
export const fetchSeminarAsync = createAsyncThunk('user/seminar', async (data) => {
  return await services.fetchSeminars(data);
});

export const fetchPostsAsync = createAsyncThunk('user/posts', async (data) => {
  return await services.fetchPosts(data);
});

export const fetchWebinarAsync = createAsyncThunk('user/webinar', async (data) => {
  return await services.fetchWebinars(data);
});

export const fetchBannerDataAsync = createAsyncThunk('user/bannerSection', async (data) => {
  return await services.fetchBannerData(data);
});

export const fetchQuoteOfTheDayDataAsync = createAsyncThunk('user/quotes', async () => {
  return await services.fetchQuoteOfTheDay();
});

export const fetchRewardDataAsync = createAsyncThunk('user/reward', async () => {
  return await services.fetchReward();
});
export const fetchNotificationDataAsync = createAsyncThunk('user/notifications', async (data) => {
  return await services.fetchNotification(data);
});
export const fetchLatestSeminarBlogAsync = createAsyncThunk('user/fetchLatestSeminarBlog', async (data) => {
  return await services.fetchLatestRecord(data);
});

export const fetchSingleNewsDetailsAsync = createAsyncThunk('user/fetchSingleNewsDetails', async (data) => {
  return await services.fetchSingleNewsDetails(data);
});

export const fetchSeminarTicketDetailsAsync = createAsyncThunk('user/fetchSeminarTicketData', async (data) => {
  return await services.fetchSeminarTicketDetails(data);
});


export const updateUserProfileAsync = createAsyncThunk('user/profilesetting', async (data) => {
  return await services.updateUserProfile(data);
});

export const updateUserProfilePicAsync = createAsyncThunk('user/profilepic', async (data) => {
  return await services.updateUserProfileImage(data);
});

export const changePasswordAsync = createAsyncThunk('user/changepassword', async (data) => {
  return await services.changePassword(data);
});

export const wantBreakAsync = createAsyncThunk('user/break', async (data) => {
  return await services.wantBreak(data);
});

export const contactUsAsync = createAsyncThunk('user/contactus', async (data) => {
  return await services.contactUs(data);
});

export const completeTaskAsync = createAsyncThunk('user/completeTask', async (data) => {
  return await services.completeTask(data);
});

export const bookSeatAsync = createAsyncThunk('user/bookSeat', async (data) => {
  return await services.bookSeat(data);
});

export const deleteNotificationAsync = createAsyncThunk('user/removeNotification', async (data) => {
  return await services.deleteNotification(data);
});

export const ResetUserStateAsync = createAsyncThunk('user/resetstate', async () => {
  return '';
});

export default userSlice.reducer;
